import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../assets/css/alertes.css";
import Services from "../services/global.service";
import Loading from "../components/loading";
import { useNavigate } from "react-router-dom";

const UserForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [roleFilters, setRoleFilters] = useState([]);
  const [profils, setProfils] = useState([]);
  const [ministeres, setMinisteres] = useState([]);
  const [showMinistere, setShowMinistere] = useState(false);
  const [saveResult, setSaveResult] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    getMinisteres();
    getProfilsAndRoles();
  }, []);

  // const getUserRoles = () => {
  //   setIsLoading(true);
  //   Services.getAll("roles/gouvalert2")
  //     .then((res) => {
  //       console.log("roles", res.data.data);
  //       var data = res.data.data;
  //       let allRoles = [];
  //       data.forEach((us) => {
  //         if (us?.roleid !== "H1") {
  //           var role = { value: us?.roleid, label: us?.rolename };
  //           allRoles.push(role);
  //         }
  //       });
  //       setRoles(allRoles);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log(err);
  //     });
  // };

  const getMinisteres = () => {
    setIsLoading(true);
    Services.getAll("groups/gouvalert2")
      .then((res) => {
        var data = res.data.data;
        let groups = [];
        data.forEach((us) => {
          var group = { value: us?.id, label: us?.value };
          groups.push(group);
        });
        setMinisteres(groups);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getProfilsAndRoles = () => {
    setIsLoading(true);
    Services.getAll("profils/roles/gouvalert2")
      .then((res) => {
        var dataProfils = res.data.data?.profils;
        var dataRoles = res.data.data?.roles;
        let Allprofils = [];
        dataProfils.forEach((us) => {
          let profil = { value: us?.id, label: us?.Libelle };
          Allprofils.push(profil);
        });
        setProfils(Allprofils);
        setRoles(dataRoles);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const loadRoleFromProfil = (e) => {
    if (e.label === "Utilisateurs Ministères") {
      setShowMinistere(true);
    } else {
      setShowMinistere(false);
    }
    let profilRoles = roles.filter((r) => r.Profils == e.value);
    let listRoles = [];
    profilRoles.forEach((us) => {
      let role = { value: us?.id, label: us?.Libelle };
      listRoles.push(role);
    });
    setRoleFilters(listRoles);
  };

  let initialValues = {
    nom: "",
    prenom: "",
    email: "",
    emailSecond: "",
    role: "",
    profil: "",
    group: "",
    password: "",
    confirmpassword: "",
    mobile: "",
  };

  const validationSchema = Yup.object().shape({
    // Define your validation schema here
    nom: Yup.string().required("Champs requis"),
    prenom: Yup.string().required("Champs requis"),
    email: Yup.string().required("Champs requis"),
    password: Yup.string().required("Champs requis"),
    confirmpassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Les mots de passe ne correspondent pas"
    ),
    mobile: Yup.string().required("Champs requis"),
  });

  const onSubmit = (values) => {
    // Handle form submission logic here
    let object = {
      Nom: values.nom,
      Prénom: values.prenom,
      Email: values.email,
      "Email Secondaire": values.emailSecond,
      Rôle: values.role.value,
      Mobile: values.mobile,
      "Mot de passe": values.password,
      Confirmation: values.confirmpassword,
      group: values.group.value,
      profil: values.profil.value,
    };
    let data = {
      setup: "gouvalert2",
      object: object,
    };
    setIsLoading(true);
    Services.create(data, "user/upsert")
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        let status = res.data.success;
        if (status) {
          setSaveResult(1);
          initialValues = {
            nom: "",
            prenom: "",
            email: "",
            emailSecond: "",
            role: "",
            password: "",
            confirmpassword: "",
            mobile: "",
          };
          navigate("/users");
        } else {
          setSaveResult(0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      {saveResult === 1 ? (
        <div class="alert alert-success" role="alert">
          Utilisateur a été enregistré avec succès
        </div>
      ) : saveResult == 2 ? (
        <div class="alert alert-danger" role="alert">
          Une erreur s'est produite.Veuillez réessayer ultérieurement
        </div>
      ) : null}

      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="row mt-42">
            {/* nom */}
            <div className="col">
              <label htmlFor="nom">Nom</label>
              <input
                type="text"
                id="nom"
                className="form-control"
                name="nom"
                value={formik.values.nom}
                onChange={(value) => {
                  formik.setFieldValue("nom", value.target.value);
                }}
              />
              {formik.errors.nom && formik.touched.nom && (
                <div className="text-danger">{formik.errors.nom}</div>
              )}
            </div>

            {/* prénom */}
            <div className="col">
              <label htmlFor="status">Prénom</label>
              <input
                type="text"
                id="prenom"
                name="prenom"
                value={formik.values.prenom}
                onChange={(value) =>
                  formik.setFieldValue("prenom", value.target.value)
                }
                className="form-control"
              />
              {formik.errors.prenom && formik.touched.prenom && (
                <div className="text-danger">{formik.errors.prenom}</div>
              )}
            </div>
          </div>
          <div className="row mt-20">
            {/* email */}
            <div className="col">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                id="email"
                placeholder="E-mail"
                name="email"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.errors.email && formik.touched.email && (
                <div className="text-danger">{formik.errors.email}</div>
              )}
            </div>
            {/* second email */}
            <div className="col">
              <label htmlFor="emailSecond">Deuxième E-mail (Optionnel)</label>
              <input
                type="email"
                id="emailSecond"
                placeholder="Deuxième E-mail (Optionnel)"
                name="emailSecond"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.emailSecond}
              />
              {formik.errors.emailSecond && formik.touched.emailSecond && (
                <div className="text-danger">{formik.errors.emailSecond}</div>
              )}
            </div>
          </div>
          {/* Profil & Role */}
          <div className="row mt-20">
            <div className="col">
              <label htmlFor="role">Profil</label>
              <Select
                id="profil"
                name="profil"
                value={formik.values.profil}
                onChange={(selectedOption) => {
                  formik.setFieldValue("profil", selectedOption);
                  loadRoleFromProfil(selectedOption);
                }}
                options={profils}
              />
              {formik.errors.profil && formik.touched.profil && (
                <div className="text-danger">{formik.errors.profil}</div>
              )}
            </div>
            <div className="col">
              <label htmlFor="role">Rôle</label>
              <Select
                id="role"
                name="role"
                value={formik.values.role}
                onChange={(selectedOption) =>
                  formik.setFieldValue("role", selectedOption)
                }
                options={roleFilters}
              />
              {formik.errors.role && formik.touched.role && (
                <div className="text-danger">{formik.errors.role}</div>
              )}
            </div>
          </div>
          {/* Ministère */}
          {showMinistere && (
            <div className="row mt-20">
              <div className="col-6">
                <label htmlFor="group">Ministère</label>
                <Select
                  id="group"
                  name="group"
                  value={formik.values.group}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("group", selectedOption);
                  }}
                  options={ministeres}
                />
                {formik.errors.group && formik.touched.group && (
                  <div className="text-danger">{formik.errors.group}</div>
                )}
              </div>
            </div>
          )}

          <div className="row mt-20">
            {/* password */}
            <div className="col">
              <label htmlFor="title">Mot de passe</label>
              <input
                type="password"
                id="password"
                placeholder="Password"
                name="password"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password && (
                <div className="text-danger">{formik.errors.password}</div>
              )}
            </div>
            {/*confirm password */}
            <div className="col">
              <label htmlFor="ticonfirmpasswordtle">
                Confirmation du mot de passe
              </label>
              <input
                type="password"
                id="confirmpassword"
                placeholder="confirmation mot du passe"
                name="confirmpassword"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmpassword}
              />
              {formik.errors.confirmpassword &&
                formik.touched.confirmpassword && (
                  <div className="text-danger">
                    {formik.errors.confirmpassword}
                  </div>
                )}
            </div>
            <div className="col">
              <label htmlFor="title">Mobile</label>
              <input
                type="mobile"
                id="mobile"
                placeholder="mobile"
                name="mobile"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
              />
              {formik.errors.mobile && formik.touched.mobile && (
                <div className="text-danger">{formik.errors.mobile}</div>
              )}
            </div>
          </div>
          <a href="/users" className="cta mt-20">
            Annuler
          </a>
          <button type="submit" className="btn-primary-al float-right mt-20">
            Sauvegarder
          </button>
        </form>
      )}
    </div>
  );
};

export default UserForm;
