import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../assets/css/modal.css";
// import Services from "../services/global.service";

import Eye from "../assets/icons/solar_eye-outline.svg";
import { useEffect, useState } from "react";
import Services from "../services/global.service";
// Importing toastify module
import { ToastContainer, toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

const ModalChangePassword = ({
  isOpen,
  updateIsOpen,
  setMessageDialogShow,
  setMessagedialogTitle,
}) => {
  const initialValues = {
    actualPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object({
    actualPassword: Yup.string()
      .required("Champs requis")
      .notOneOf(
        [Yup.ref("newPassword")],
        "Le mot de passe réel doit être différent du nouveau mot de passe"
      ),
    newPassword: Yup.string()
      .required("Champs requis")
      .min(8, "Le mot de passe doit comporter au moins 8 caractères"),
    confirmPassword: Yup.string()
      .required("Confirmer mot de passe requis")
      .oneOf(
        [Yup.ref("newPassword"), null],
        "Les mots de passe doivent correspondre"
      ),
  });

  const onSubmit = (values) => {
    // setIsLoading(true);
    if (values.newPassword !== values.confirmPassword) {
      toast.error("Les mots de passe ne correspondent pas");
      return;
    }
    const data = {
      setup: "gouvalert2",
      email: localStorage.getItem("__email"),
      oldpassword: values.actualPassword,
      newpassword: values.newPassword,
    };
    Services.getAllByData("user/modify/yourpasswd", data)
      .then((res) => {
        if (res.data.success == true) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setTimeout(function () {
          updateIsOpen(false);
        }, 1500);

        // setMessagedialogTitle(res.data.message);
        //setMessageDialogShow(true);
        // setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Une erreur s'est produite");
      });
  };
  const hideModal = () => {
    updateIsOpen();
  };
  const handleToggle = (type) => () => {
    console.log(type);
    if (type === "old") {
      setShowPassword(!showPassword);
    }
    if (type === "confirm") {
      setShowConfirmPassword(!showconfirmPassword);
    }

    if (type === "new") {
      console.log("here");
      setShowNewPassword(!showNewPassword);
    } // type==='new' ? setShowPassword(!showPassword) :setShowConfirmPassword(!showconfirmPassword);
  };

  useEffect(() => {}, []);

  return (
    <Modal show={isOpen} onHide={updateIsOpen} className="modal-up">
      <Modal.Header>
        <Modal.Title>
          <h3>Changer votre mot de passe</h3>
        </Modal.Title>
        <button className="btn btn-link" onClick={() => updateIsOpen()}>
          {" "}
        </button>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form className=" m-auto">
            <div className=" mt-2">
              <div className="password-field">
                <label htmlFor="pseudo">Ancien mot de passe</label>
                <div className="password-input-container">
                  <Field
                    name="actualPassword"
                    className="form-control"
                    type={showPassword ? "text" : "password"}
                    placeholder="Renseignez votre mot de passe"
                  />
                  {showPassword ? (
                    <img
                      src={Eye}
                      className="password-icon"
                      alt="password show"
                      onClick={handleToggle("old")}
                    />
                  ) : (
                    <img
                      src={Eye}
                      className="password-icon"
                      alt="password-hide"
                      onClick={handleToggle("old")}
                    />
                  )}
                </div>{" "}
                <ErrorMessage
                  name="actualPassword"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="password-field">
                <label htmlFor="pseudo">Nouveau mot de passe</label>{" "}
                <div className="password-input-container">
                  <Field
                    name="newPassword"
                    className="form-control"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Renseignez votre mot de passe"
                  />
                  {showNewPassword ? (
                    <img
                      src={Eye}
                      className="password-icon"
                      alt="password show"
                      onClick={handleToggle("new")}
                    />
                  ) : (
                    <img
                      src={Eye}
                      className="password-icon"
                      alt="password-hide"
                      onClick={handleToggle("new")}
                    />
                  )}
                </div>{" "}
                <ErrorMessage
                  name="newPassword"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className=" password-field">
                <label htmlFor="pseudo">Confirmez votre mot de passe</label>
                <div className="password-input-container">
                  <Field
                    name="confirmPassword"
                    className="form-control"
                    type={showconfirmPassword ? "text" : "password"}
                    placeholder="Confirmez votre mot de passe"
                  />
                  {showconfirmPassword ? (
                    <img
                      src={Eye}
                      className="password-icon"
                      alt="password show"
                      onClick={handleToggle("confirm")}
                    />
                  ) : (
                    <img
                      src={Eye}
                      className="password-icon"
                      alt="password-hide"
                      onClick={handleToggle("confirm")}
                    />
                  )}
                </div>{" "}
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <button
              onClick={() => {
                updateIsOpen();
              }}
              className="cta mt-3 "
            >
              Annuler
            </button>
            <button type="submit" className="btn-primary-al mt-3 float-right">
              Valider
            </button>
            <ToastContainer />
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ModalChangePassword;
