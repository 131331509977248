import axios from "axios";
const API_URL =
  process.env.URL_BACKEND ||
  "https://gouvalert2.alcyon-partners.net/gouv/public/api/";
// const API_URL =
//   process.env.URL_BACKEND || "http://127.0.0.1/gouvalert/public/api/";
const setupApp = "gouvalert2";
const token = localStorage.getItem("token");
const headersFiles = {
  headers: {
    Accept: "application/json",
    Authorization: "Bearer " + token,
  },
};
const login = async (email, password) => {
  return axios
    .post(API_URL + "user/login", {
      login: email,
      password: password,
      setup: setupApp,
    })
    .then((response) => {
      if (response) {
        localStorage.setItem("nom", response.data.data?.Nom);
        localStorage.setItem("prenom", response.data.data?.Prénom);
        localStorage.setItem("role", response.data.data?.role);
        localStorage.setItem("userid", response.data.data?.id);
        localStorage.setItem("profil", response.data.data?.profil);
        localStorage.setItem("group", response.data.data?.group);
        // localStorage.setItem('token', response.data.token);
        // localStorage.setItem('id', response.data._id);
      }
      return response.data;
    })
    .catch((err) => {
      console.log("erreur login", err);
      throw err; // Throw the error to be caught in the promise chain
    });
};

const changePassword = (token, password) => {
  return axios
    .post(API_URL + "auth/change-password", {
      token: token,
      password: password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("erreur chnagepass", err);
      return err;
    });
};
const resetPassword = (email) => {
  return axios
    .post(API_URL + "user/modify/passwd", {
      email: email,
      setup: "gouvalert2",
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("erreur login", err);
      return err;
    });
};

const getCurrentUser = () => {
  const id = localStorage.getItem("id");
  if (id) return id;
};
const profil = () => {
  const userId = getCurrentUser();
  return axios
    .get(API_URL + "user/get/" + userId, headersFiles)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return null;
    });
};
const PasswordChange = async (data) => {
  const user = await profil();
  const datas = {
    email: user?.email,
    currentPassword: data?.actualPassword,
    newPassword: data?.newPassword,
  };
  return axios
    .post(API_URL + "auth/password-change", datas)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("erreur chnagepass", err);
      return err;
    });
};
const AuthService = {
  profil,
  login,
  resetPassword,
  changePassword,
  PasswordChange,
};

export default AuthService;
