import axios from "axios";
const API_URL =
  process.env.URL_BACKEND ||
  "https://gouvalert2.alcyon-partners.net/gouv/public/api/";
// const API_URL =
//   process.env.URL_BACKEND || "http://127.0.0.1/gouvalert/public/api/";
const SETUP = "gouvalert2";
const token = localStorage.getItem("token");

const headers = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  },
};
const headersFiles = {
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + token,
  },
};

const getAll = (endpoint) => {
  return axios.get(API_URL + endpoint, headers);
};

const getAllByData = (endpoint, data) => {
  try {
    return axios.post(API_URL + endpoint, data);
  } catch (e) {
    console.log(e);
  }
};

const getOne = (id, endpoint) => {
  return axios.get(API_URL + endpoint + `/${id}`, headers);
};

const create = (data, endpoint, isupload = false) => {
  return axios.post(
    API_URL + endpoint,
    data,
    isupload ? headersFiles : headers
  );
};

const deleteByid = (id, endpoint) => {
  return axios.delete(API_URL + endpoint + `/${id}`, headers);
};

const update = (data, id, endpoint) => {
  return axios.post(API_URL + endpoint + `/update/${id}`, data, headers);
};

const updatewithoutID = (data, endpoint) => {
  return axios.post(API_URL + endpoint + `/update`, data, headers);
};
const getByRole = (endpoint, role) => {
  return axios.get(API_URL + endpoint + `/${role}`, headers);
};

//////get data for selected Input
const getDataSelectedInput = () => {
  let endpoints = [
    API_URL + "enum/Priorité/" + SETUP,
    API_URL + "enum/Statut/" + SETUP,
    //    API_URL + "enum/Catégorie/"+SETUP,
    API_URL + "enum/Etat/" + SETUP,
    API_URL + "groups/" + SETUP,
  ];
  return axios.all(endpoints.map((endpoint) => axios.get(endpoint, headers)));
};

/////get Authorization
const getAuthorization = (key) => {
  //get Authorizations all
  const authorizations =
    localStorage.getItem("__authorizations") !== undefined
      ? JSON.parse(localStorage.getItem("__authorizations"))
      : [];
  let permit = false;
  console.log("key", key);
  console.log("authorizations", authorizations);
  console.log("authorization key", authorizations[key]);
  if (authorizations.hasOwnProperty(key) && authorizations[key] === "1") {
    permit = true;
  }
  return permit;
};

const Services = {
  getAll,
  getOne,
  create,
  deleteByid,
  update,
  updatewithoutID,
  getByRole,
  getAllByData,
  getDataSelectedInput,
  getAuthorization,
};
export default Services;
