import Layout from "./layout";
import '../assets/css/alertes.css'
import AlertForm from "../components/formAlerte";
import Warining from '../assets/icons/fluent_warning-32-filled.svg'
import chevron from '../assets/icons/ep_arrow-up.svg';
import iconBack from '../assets/icons/mingcute_arrow-up-line.svg';
import AlertFormUpdate from "../components/updateFormAlerte";
import InterventionFormUpdate from "../components/updateFormIntervention";
function UpdateIntervention(){
  


    return (
        <Layout>
            <div className="min-vh-100 bg-color container-al">
                <div className="">
                <div className="bread-crumb">
                      <span className="bread-item">Liste des interventions  </span>
                      <img src={chevron} alt="chevron"></img>
                    <span className="bread-item">Modifier intervention</span> 
                    </div>
                    <div className="retour pt-26 mb-20">
                    <img src={iconBack} alt="iconBack"></img>
                    <a href='/interventions' className="retour-link">Retour</a>
                    </div>
                <div className="row">
                        <div className="col-md-12"><h1>Modifier intervention 
                            {/* <img src={Warining} alt="warning icon" className="mx-2"></img> */}
                            </h1></div>
                        <hr></hr>
                    </div>  
                    <InterventionFormUpdate/>
                </div>  
            </div>
          
        </Layout>
    );
}
export default UpdateIntervention;