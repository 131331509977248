import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import "../assets/css/sidebar.css";
import logo from "../assets/icons/logo.png";
import home from "../assets/icons/uiw_home.svg";
import homeActive from "../assets/icons/uiw_home-white.svg";
import alerte from "../assets/icons/fluent_warning.svg";
import alerteActive from "../assets/icons/fluent_warning-white.svg";
import userIcon from "../assets/icons/ph_user-circle-light.svg";
import intervention from "../assets/icons/interventions.svg";
import interventionActive from "../assets/icons/tabler_focus-2active.svg";
import rapports from "../assets/icons/system-uicons_document.svg";
import rapportsActive from "../assets/icons/system-uicons_documentactive.svg";
import users from "../assets/icons/ph_users-light.svg";
import usersActive from "../assets/icons/ph_users-lightActive.svg";
import ModalChangePassword from "../components/changePassword";
import { useNavigate } from "react-router-dom";
import Constant from "../utils/constant";
import ConfirmDeleteModal from "./confirmDeleteModal";


function SideBar() {
  const navigate = useNavigate();
  const [closeSideBar, setCloseSideBar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [menus, setMenus] = useState([]);
  const [messageDialogShow, setMessageDialogShow] = useState(false);
  const [messageDialogShowTitle, setMessageDialogShowTitle] = useState(false);

  const role = localStorage.getItem("role");
  const profil = localStorage.getItem("__profil");
  const main_menu =
    localStorage.getItem("__menus") != undefined
      ? JSON.parse(localStorage.getItem("__menus"))
      : [];

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("__profil");
    localStorage.removeItem("__role");
    localStorage.removeItem("__email");
    localStorage.removeItem("__group");
    localStorage.removeItem("__id");
    localStorage.removeItem("__authorizations");
    localStorage.removeItem("__menus");
    localStorage.removeItem("__expired_myToken");
    localStorage.removeItem("nom");
    localStorage.removeItem("prenom");
    localStorage.removeItem("role");
    localStorage.removeItem("userid");
    localStorage.removeItem("profil");
    localStorage.removeItem("group");
    navigate("/login");
  };
  const handleModal = () => {
    setIsOpen(true);
  };
  const updateIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const hideMessageDialogModal = () => {
    setMessageDialogShow(!messageDialogShow);
  };
  useEffect(() => {
    loadMenus();
  }, [isOpen]);

  const loadMenus = () => {
    let m = [];
    if (main_menu.Dashboard === "1") {
      let d = {
        route: "/dashboard",
        name: "Tableau de bord",
        alt: "Tableau de bord",
        imgActif: homeActive,
        imgNotActif: home,
      };
      m.push(d);
    }
    if (main_menu.Alerte === "1") {
      let a = {
        route: "/alertes",
        name: "Alertes",
        alt: "Alerte",
        imgActif: alerteActive,
        imgNotActif: alerte,
      };
      m.push(a);
    }
    if (main_menu.Interventions === "1") {
      let i = {
        route: "/interventions",
        name: "Interventions",
        alt: "Intervention",
        imgActif: interventionActive,
        imgNotActif: intervention,
      };
      m.push(i);
    }
    if (main_menu.Rapport === "1") {
      let r = {
        route: "/rapports",
        name: "Rapports",
        alt: "Rapport",
        imgActif: rapportsActive,
        imgNotActif: rapports,
      };
      m.push(r);
    }
    if (main_menu.Utilisateurs === "1") {
      let u = {
        route: "/users",
        name: "Utilisateurs",
        alt: "Utilisateur",
        imgActif: usersActive,
        imgNotActif: users,
      };
      m.push(u);
    }
    setMenus(m);
  };

  return (
    <div className={`col-auto col-xs-1 col-md-2 col-xl-2 px-0 `}>
      <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white min-vh-100">
        <a
          className="btn btn-link  d-sm-none  d-none d-lg-inline"
          href="/dashboard"
        >
          <img src={logo} alt="logo" className=" res-none logo" />
        </a>
        {isOpen ? (
          <ModalChangePassword
            isOpen={isOpen}
            updateIsOpen={updateIsOpen}
            setMessageDialogShow={setMessageDialogShow}
            setMessagedialogTitle={setMessageDialogShowTitle}
          />
        ) : null}
        {messageDialogShow ? (
          <ConfirmDeleteModal
            isOpenDelete={messageDialogShow}
            setIsOpenDelete={setMessageDialogShow}
            hideModal={hideMessageDialogModal}
            title={messageDialogShowTitle}
          />
        ) : (
          ""
        )}
        <Nav className="flex-column">
          {menus.map((item, key) => (
            <NavLink
              key={key}
              to={item.route}
              className={({ isActive }) =>
                isActive ? "nav-item active" : "nav-item "
              }
            >
              {({ isActive }) => (
                <>
                  <img
                    src={isActive ? item.imgActif : item.imgNotActif}
                    alt={item.alt}
                    className="nav-img ml-15"
                  />
                  {!closeSideBar && (
                    <span className=" d-sm-none d-none d-lg-inline">
                      {item.name}
                    </span>
                  )}
                </>
              )}
            </NavLink>
          ))}
        </Nav>
      </div>
      <div className="footer-sidebar mx-3">
        <div className="d-flex align-items-center ">
          <div className="">
            <img src={userIcon} className="ml-15" alt="user icon" />
          </div>{" "}
          <div className="col res-none  d-none d-lg-inline">
            <label>{localStorage.getItem("nom")}</label> <br></br>
            <label>{localStorage.getItem("prenom")}</label>{" "}
          </div>
        </div>
        <div className=" mt-33">
          <button
            onClick={() => {
              handleModal();
            }}
            className="cta  d-none d-lg-inline res-none"
          >
            {" "}
            Modifier le mot de passe
          </button>
        </div>

        <div className=" mt-24">
          <button
            onClick={() => {
              logOut();
            }}
            className="cta  res-none  d-none d-lg-inline"
          >
            {" "}
            Déconnexion
          </button>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
