import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../assets/css/alertes.css";
import Services from "../services/global.service";
import Loading from "../components/loading";
import Constant from "../utils/constant";

const UserFormUpdate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [saveResult, setSaveResult] = useState(0);
  const [roleFilters, setRoleFilters] = useState([]);
  const [profils, setProfils] = useState([]);
  const [ministeres, setMinisteres] = useState([]);
  const [showMinistere, setShowMinistere] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const idUser = params.id;

  useEffect(() => {
    getMinisteres();
  }, []);

  const getProfilAndRoleUser = (dataprofils, dataroles, groups) => {
    Services.getAll("user/profil/" + idUser + "/gouvalert2")
      .then((res) => {
        let role = res.data?.data[0]?.Roles;
        let profil = res.data?.data[0]?.Profils;
        let group = res?.data?.data[0]?.ministeres;
        let selectedRole = dataroles.find(function (elmnt) {
          return elmnt.value === role;
        });
        let selectedProfil = dataprofils.find(function (elmnt) {
          return elmnt.value === profil;
        });
        let selectedGroup = groups.find(function (elmnt) {
          return elmnt.value === group;
        });
        getDetails(selectedRole, selectedProfil, selectedGroup);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getDetails = (selectedRole, selectedProfil, selectedGroup) => {
    if (idUser.length === 0) {
      navigate("/users");
    }
    const data = {
      setup: Constant.SETUP,
      recordId: idUser,
      moduleName: Constant.USERS,
      role: localStorage.getItem("__role"),
    };
    if (selectedGroup != null || selectedGroup != undefined) {
      setShowMinistere(true);
    }
    Services.getAllByData("module/detail", data)
      .then((res) => {
        setIsLoading(false);
        let detail = res?.data?.data?.Resume[0];
        let detailUser = {
          nom: detail?.Nom,
          prenom: detail["Prénom"],
          email: detail["Email"],
          emailSecond: detail["Email Secondaire"],
          mobile: detail["Mobile"],
          role:
            selectedRole != null && selectedRole != undefined
              ? selectedRole
              : "",
          profil:
            selectedProfil != null && selectedProfil != undefined
              ? selectedProfil
              : "",
          group:
            selectedGroup != null && selectedGroup != undefined
              ? selectedGroup
              : "",
        };
        setInitialValues(detailUser);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getProfilsAndRoles = (groups) => {
    Services.getAll("profils/roles/gouvalert2")
      .then((res) => {
        var dataProfils = res.data.data?.profils;
        var dataRoles = res.data.data?.roles;
        let Allprofils = [];
        let AllRoles = [];
        dataProfils.forEach((us) => {
          let profil = { value: us?.id, label: us?.Libelle };
          Allprofils.push(profil);
        });
        dataRoles.forEach((us) => {
          let role = { value: us?.id, label: us?.Libelle };
          AllRoles.push(role);
        });
        setProfils(Allprofils);
        setRoles(AllRoles);
        getProfilAndRoleUser(Allprofils, AllRoles, groups);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getMinisteres = () => {
    setIsLoading(true);
    Services.getAll("groups/gouvalert2")
      .then((res) => {
        var data = res.data.data;
        let groups = [];
        data.forEach((us) => {
          var group = { value: us?.id, label: us?.value };
          groups.push(group);
        });
        setMinisteres(groups);
        getProfilsAndRoles(groups);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const loadRoleFromProfil = (e) => {
    if (e.label === "Utilisateurs Ministères") {
      setShowMinistere(true);
    } else {
      setShowMinistere(false);
    }
    let profilRoles = roles.filter((r) => r.Profils == e.value);
    let listRoles = [];
    profilRoles.forEach((us) => {
      let role = { value: us?.id, label: us?.Libelle };
      listRoles.push(role);
    });
    setRoleFilters(listRoles);
  };

  const validationSchema = Yup.object().shape({
    // Define your validation schema here
    nom: Yup.string().required("Champs requis"),
    prenom: Yup.string().required("Champs requis"),
    email: Yup.string().required("Champs requis"),
    // mobile: Yup.string().required("Champs requis"),
    // profil: Yup.string().required("Champs requis"),
    // role: Yup.string().required("Champs requis"),
  });

  const onSubmit = (values) => {
    // Handle form submission logic here
    let object = {
      id: idUser,
      Nom: values.nom,
      Prénom: values.prenom,
      Email: values.email,
      "Email Secondaire": values.emailSecond,
      Mobile: values.mobile,
    };
    if (values.role) {
      object["Rôle"] = values.role.value;
    }
    if (values.profil) {
      object["profil"] = values.profil.value;
    }
    if (values.group) {
      object["group"] = values.group.value;
    }
    let data = {
      setup: "gouvalert2",
      object: object,
    };
    setIsLoading(true);
    Services.create(data, "user/upsert")
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        let status = res.data.success;
        if (status) {
          setSaveResult(1);
          initialValues = {
            nom: "",
            prenom: "",
            email: "",
            emailSecond: "",
            role: "",
            password: "",
            confirmpassword: "",
            mobile: "",
          };
          navigate("/users");
        } else {
          setSaveResult(0);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      {saveResult === 1 ? (
        <div class="alert alert-success" role="alert">
          Utilisateur a été modifié avec succès
        </div>
      ) : saveResult == 2 ? (
        <div class="alert alert-danger" role="alert">
          Une erreur s'est produite.Veuillez réessayer ultérieurement
        </div>
      ) : null}
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="row mt-42">
            {/* nom */}
            <div className="col">
              <label htmlFor="nom">Nom</label>
              <input
                type="text"
                id="nom"
                className="form-control"
                name="nom"
                value={formik.values.nom}
                onChange={(value) =>
                  formik.setFieldValue("nom", value.target.value)
                }
              />
              {formik.errors.nom && formik.touched.nom && (
                <div className="text-danger">{formik.errors.nom}</div>
              )}
            </div>

            {/* prénom */}
            <div className="col">
              <label htmlFor="status">Prénom</label>
              <input
                type="text"
                id="prenom"
                name="prenom"
                value={formik.values.prenom}
                onChange={(value) =>
                  formik.setFieldValue("prenom", value.target.value)
                }
                className="form-control"
              />
              {formik.errors.prenom && formik.touched.prenom && (
                <div className="text-danger">{formik.errors.prenom}</div>
              )}
            </div>
          </div>
          <div className="row mt-20">
            {/* email */}
            <div className="col">
              <label htmlFor="email">E-mail</label>
              <input
                type="email"
                id="email"
                placeholder="E-mail"
                name="email"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                disabled={true}
              />
              {formik.errors.email && formik.touched.email && (
                <div className="text-danger">{formik.errors.email}</div>
              )}
            </div>
            {/* second email */}
            <div className="col">
              <label htmlFor="emailSecond">Deuxième E-mail (Optionnel)</label>
              <input
                type="email"
                id="emailSecond"
                placeholder="Deuxième E-mail (Optionnel)"
                name="emailSecond"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.emailSecond}
              />
              {formik.errors.emailSecond && formik.touched.emailSecond && (
                <div className="text-danger">{formik.errors.emailSecond}</div>
              )}
            </div>
          </div>
          <div className="row mt-20">
            <div className="col">
              <label htmlFor="role">Profil</label>
              <Select
                id="profil"
                name="profil"
                isDisabled={true}
                value={formik.values.profil}
                onChange={(selectedOption) => {
                  formik.setFieldValue("e", selectedOption);
                  loadRoleFromProfil(selectedOption);
                }}
                options={profils}
              />
              {formik.errors.profil && formik.touched.profil && (
                <div className="text-danger">{formik.errors.profil}</div>
              )}
            </div>
            <div className="col">
              <label htmlFor="role">Rôle</label>
              <Select
                id="role"
                name="role"
                isDisabled={true}
                value={formik.values.role}
                onChange={(selectedOption) =>
                  formik.setFieldValue("role", selectedOption)
                }
                options={roleFilters}
              />
              {formik.errors.role && formik.touched.role && (
                <div className="text-danger">{formik.errors.role}</div>
              )}
            </div>

            <div className="col">
              <label htmlFor="title">Mobile</label>
              <input
                type="mobile"
                id="mobile"
                placeholder="mobile"
                name="mobile"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
              />
              {formik.errors.mobile && formik.touched.mobile && (
                <div className="text-danger">{formik.errors.mobile}</div>
              )}
            </div>
          </div>
          {/* Ministère */}
          {showMinistere && (
            <div className="row mt-20">
              <div className="col-6">
                <label htmlFor="group">Ministère</label>
                <Select
                  id="group"
                  name="group"
                  value={formik.values.group}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("group", selectedOption);
                  }}
                  options={ministeres}
                />
                {formik.errors.group && formik.touched.group && (
                  <div className="text-danger">{formik.errors.group}</div>
                )}
              </div>
            </div>
          )}
          <a href="/users" className="cta mt-20">
            Annuler
          </a>
          <button type="submit" className="btn-primary-al float-right mt-20">
            Modifier
          </button>
        </form>
      )}
    </div>
  );
};

export default UserFormUpdate;
