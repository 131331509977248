import Layout from "./layout";
import "../assets/css/rapport.css";
import Warining from "../assets/icons/fluent_warning-32-filled.svg";
import edit from "../assets/icons/cil_pen.svg";
import passwordChange from "../assets/icons/change-password-icon.svg";
import eye from "../assets/icons/solar_eye-outline.svg";
import trash from "../assets/icons/iconoir_trash.svg";
import Datatables from "../components/datatable";
import { useEffect, useState } from "react";
import DeleteModal from "../components/deleteModal";
import DetailsUser from "../components/detailsUser";
import Loading from "../components/loading";
import Constant from "../utils/constant";
import Services from "../services/global.service";
import ConfirmDeleteModal from "../components/confirmDeleteModal";
import ChangePasswordModal from "../components/changePasswordModal";

function Users() {
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const [isDeleteConfirmMessage, setIsDeleteConfirmMessage] = useState("");
  const [detailsUser, setDetailsUser] = useState({});
  const [detailsUserDelete, setDetailsUserDelete] = useState({});
  const [detailsUserChangePwd, setDetailsUserChangePwd] = useState({});
  const [users, setUsers] = useState({});
  const [permitCreateUser, setPermitCreateUser] = useState(false);
  const [permitUpdateUser, setPermitUpdateUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [profils, setProfils] = useState([]);

  const columns = [
    {
      selector: (row) => row.userName,
      name: "Nom de l'utilisateur",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Prénom",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Nom de famille",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => row.tel,
      sortable: true,
    },
    {
      name: "Actions",
      width: "15%",
      cell: (row) => (
        <div>
          <button
            className="cta"
            onClick={() => handleDetailsUser(row)}
            title="Detail User"
          >
            <img src={eye} alt="icon eye" className="w-75" />
          </button>
          {permitUpdateUser && (
            <a
              className="cta"
              href={`/users/update/${row.__id}`}
              title="Modifier Utilisateur"
            >
              <img src={edit} alt="icon edit" className="w-75" />
            </a>
          )}
          {permitUpdateUser && (
            <button
              className="cta"
              onClick={() => handlePasswordChange(row)}
              title="Changer Mot de passe"
            >
              <img src={passwordChange} alt="icon edit" className="w-250" />
            </button>
          )}
          <button
            className="cta"
            onClick={() => handleDelete(row)}
            title="Supprimer Utilisateur"
          >
            <img src={trash} alt="icon delete" className="w-75" />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  //////get Users list
  const getUsers = () => {
    setIsLoading(true);
    const data = {
      setup: "gouvalert2",
      moduleName: Constant.USERS,
      role: localStorage.getItem("role"),
      group: localStorage.getItem("group"),
      profil: localStorage.getItem("profil"),
      userid: localStorage.getItem("userid"),
    };
    Services.getAllByData("module/list", data)
      .then((res) => {
        var data = res.data.data;
        let users = [];
        data.forEach((us) => {
          if (us.Admin != "on") {
            var user = {
              __id: us?.id,
              userName: us["Nom d'Utilisateur"],
              email: us["Email"],
              firstName: us["Nom"],
              lastName: us["Prénom"],
              tel: us["Mobile"],
              role: us["Role"],
            };
            users.push(user);
          }
        });
        setUsers(users);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  //////get Roles
  const getUserRoles = () => {
    setIsLoading(true);
    Services.getAll("profils/roles/gouvalert2")
      .then((res) => {
        console.log("roles", res.data.data);
        var dataRoles = res.data.data?.roles;
        var dataProfils = res.data.data?.profils;
        setRoles(dataRoles);
        setProfils(dataProfils);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // Add your functions for handling button clicks
  const handleDelete = (row) => {
    setDetailsUserDelete(row);
    setIsOpenDelete(!isOpenDelete);
  };

  const handleDetailsUser = (row) => {
    setIsLoading(true);
    Services.getAll("user/profil/" + row.__id + "/gouvalert2")
      .then((res) => {
        console.log("roles", res.data.data);
        let role = res.data?.data[0]?.Roles;
        let profil = res.data?.data[0]?.Profils;
        let selectedRole = roles.find(function (elmnt) {
          return elmnt.id === role;
        });
        let selectedProfil = profils.find(function (elmnt) {
          return elmnt.id === profil;
        });
        row["Role"] = selectedRole.Libelle;
        row["Profil"] = selectedProfil.Libelle;
        setIsLoading(false);
        setDetailsUser(row);
        setIsOpenDetails(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const hideModal = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const hideConfirmDeleteModal = () => {
    setIsOpenDelete(!isDeleteConfirm);
  };

  const hideChangePasswordModal = () => {
    setIsOpenChangePassword(!isOpenChangePassword);
  };

  const hideModaldetails = () => {
    setIsOpenDetails(!isOpenDetails);
  };

  const userCreateAuthorization = () => {
    let permit = Services.getAuthorization("Create account");
    setPermitCreateUser(permit);
    let permitUpdate = Services.getAuthorization("Update Account");
    setPermitUpdateUser(permitUpdate);
  };

  const handlePasswordChange = (row) => {
    setDetailsUserChangePwd(row);
    setIsOpenChangePassword(!isOpenChangePassword);
  };

  useEffect(() => {
    getUsers();
    getUserRoles();
    userCreateAuthorization();
  }, []);

  function DeleteUser() {
    if (detailsUserDelete.length == 0) {
      return;
    }
    setIsLoading(true);
    const data = {
      setup: "gouvalert2",
      email: detailsUserDelete.email,
    };
    Services.getAllByData("user/delete", data)
      .then((res) => {
        setIsOpenDelete(false);
        setIsDeleteConfirmMessage(res.data.message);
        setIsDeleteConfirm(true);
        setIsLoading(false);
        getUsers();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  function changePasswordUser() {
    if (detailsUserChangePwd.length == 0) {
      return;
    }
    setIsLoading(true);
    const data = {
      setup: "gouvalert2",
      email: detailsUserChangePwd.email,
    };
    Services.getAllByData("user/modify/passwd", data)
      .then((res) => {
        setIsOpenChangePassword(false);
        setIsDeleteConfirmMessage(res.data.message);
        setIsDeleteConfirm(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  return (
    <Layout>
      {isOpenDelete ? (
        <DeleteModal
          isOpenDelete={isOpenDelete}
          setIsOpenDelete={setIsOpenDelete}
          hideModal={hideModal}
          DeleteFunction={DeleteUser}
          title="Souhaitez-vous supprimer cet utilisateur?"
        />
      ) : (
        ""
      )}
      {isOpenDetails ? (
        <DetailsUser
          isOpenDetails={isOpenDetails}
          setIsOpenDetails={setIsOpenDetails}
          hideModal={hideModaldetails}
          data={detailsUser}
        />
      ) : (
        ""
      )}
      {isDeleteConfirm ? (
        <ConfirmDeleteModal
          isOpenDelete={isDeleteConfirm}
          setIsOpenDelete={setIsDeleteConfirm}
          hideModal={hideConfirmDeleteModal}
          title={isDeleteConfirmMessage}
        />
      ) : (
        ""
      )}
      {isOpenChangePassword ? (
        <ChangePasswordModal
          isOpenDelete={isOpenChangePassword}
          setIsOpenDelete={setIsOpenChangePassword}
          hideModal={hideChangePasswordModal}
          DeleteFunction={changePasswordUser}
          title="Souhaitez-vous modifier le mot de passe utilisateur"
        />
      ) : (
        ""
      )}
      <div className="min-vh-100 bg-color container-al">
        <div className=" ">
          <div className="row">
            <div className="col-md-6">
              <h1>Liste des utilisateurs </h1>
            </div>
            <div className="col-md-6 text-right">
              {permitCreateUser && (
                <a
                  className="btn-primary-al mx-3 float-right"
                  href="/users/create"
                >
                  Créer utilisateur
                </a>
              )}
            </div>
          </div>
          {/* <input className="form-control mt-42" type="search" id="search" placeholder="Recherche"/> */}

          {isLoading ? (
            <Loading />
          ) : (
            <div className="container mt-42 bg-primary-al">
              <Datatables data={users} tabs={[]} columns={columns} path={``} />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
export default Users;
