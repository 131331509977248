import DataTable from "react-data-table-component";
import "../App.css";
import { useEffect, useMemo, useState } from "react";

export default function DatatablesRapport({ data, columns }) {
  const customStyles = {
    table: {
      style: {
        //   backgroundColor: '#FCFCFC',
        fontSize: "16px",
        // border: "0.5px solid #DEDEDE",
        borderRadius: "10px",
      },
    },
    headCells: {
      style: {
        // backgroundColor: '#F4F3F3',
        // border: "0.5px solid #FCFCFC",
        // borderRadius:"10px 10px 0 0",
        // borderBottomStyle:"hidden",
        fontSize: "16px",
        fontFamily: "DM-Sans-Medium",
        fontWeight: "600",
        //  marginLeft:'10px',
      },
    },
    headRow: {
      style: {
        display: "none",
      },
    },
    rows: {
      style: {
        backgroundColor: "#fff",
        // borderRadius:"20px",
        // margin:"10px",
        // width:'98%',
        // boxShadow:"0px 4px 20px rgba(0, 0, 0, 0.1)",
        borderBottomStyle: "#DEDEDE",
        borderBottomWidth: "90%",
        fontSize: "16px",
        fontFamily: "DM-Sans-Regular",
        minHeight: "64px",
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Lignes par page",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tous",
  };

  useEffect(() => {}, [data]);

  return (
    <>
      <div className="col-md-12 mt-24">
        {data && (
          <DataTable
            columns={columns}
            data={data}
            className="datatable-all mt-24"
            //pagination
            // paginationComponentOptions={paginationComponentOptions}
            customStyles={customStyles}
          />
        )}
      </div>
    </>
  );
}
