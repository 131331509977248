import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import closeIcon from "../assets/icons/close-outline.svg";
// import '../assets/css/modal.css';
const DetailsUser = ({ isOpenDetails, setIsOpenDetails, hideModal, data }) => {
  return (
    <Modal show={isOpenDetails} onHide={hideModal} className="modal-up">
      <ModalHeader>
        {" "}
        <h3 className="text-center"> Détails utilisateur </h3>
        <button
          className="btn btn-link float-right"
          onClick={() => setIsOpenDetails(false)}
        >
          <img src={closeIcon} alt="close icon" />{" "}
        </button>
      </ModalHeader>
      <ModalBody>
        <hr />

        <div className="row pt-20">
          <div className="row">
            <div className="col">
              {" "}
              <h5>
                Nom: <label> {data?.firstName}</label>
              </h5>
            </div>
            <div className="col">
              <h5>
                Prénom: <label>{data?.lastName}</label>
              </h5>
            </div>
          </div>{" "}
          <div className="row">
            <div className="col">
              {" "}
              <h5>
                E-mail: <label>{data?.email}</label>
              </h5>
            </div>
            <div className="col">
              <h5>
                Num téléphone: <label> {data?.tel}</label>
              </h5>
            </div>
          </div>{" "}
          <div className="row">
            <div className="col">
              {" "}
              <h5>
                Role: <label> {data?.Role}</label>
              </h5>
            </div>
            <div className="col">
              {" "}
              <h5>
                Profil: <label> {data?.Profil}</label>
              </h5>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default DetailsUser;
