import Layout from "./layout";
import "../assets/css/rapport.css";
import { useRef, useEffect, useState } from "react";
import Warining from "../assets/icons/fluent_warning-32-filled.svg";
import Datatables from "../components/datatable";
import { useParams } from "react-router";
import chevron from "../assets/icons/ep_arrow-up.svg";
import iconBack from "../assets/icons/mingcute_arrow-up-line.svg";
import mailIcon from "../assets/icons/ion_mail-outline.svg";
import downloadIcon from "../assets/icons/lucide_download.svg";
import mediumIcon from "../assets/icons/fluent_info-24-regular.svg";
import urlData from "../assets/uploads/upload1.png";
import DatatablesRapport from "../components/datatableRapport";
import Services from "../services/global.service";
import Constant from "../utils/constant";
import Loading from "../components/loading";
import jsPDF from "jspdf";

function RapportIntervention() {
  const [isLoading, setIsLoading] = useState(false);
  const [interventions, setInterventions] = useState([]);
  const [isSendRapport, SetIsSendRapport] = useState(false);
  const rapportRef = useRef(null);
  const [isShowTag, setIsShowTag] = useState(true);

  const profil = localStorage.getItem("__profil");

  const convertImageToBase64 = (imageURI) => {
    var src = "data:image/png;base64, " + imageURI;
    return src;
  };

  const downloadRapport = () => {
    setIsShowTag(false);
    setTimeout(() => {
      var content = rapportRef.current;
      setIsLoading(true);
      let pdf = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: "a4",
      });

      console.log(content);
      console.log(rapportRef.current.children);
      const width = pdf.internal.pageSize.getWidth();
      pdf
        .html(content, {
          width: width,
          windowWidth: 794,
          margin: [80, 80, 80, 80],
          html2canvas: { scale: 0.57, useCORS: true },
        })
        .then(() => {
          pdf.save("Rapport_Intervention.pdf");
          setIsShowTag(true);
          setIsLoading(false);
        });
    }, 0);
  };

  const getListIntervetions = () => {
    setIsLoading(true);
    const data = {
      setup: "gouvalert2",
      moduleName: "Intervention",
      role: localStorage.getItem("role"),
      group: localStorage.getItem("group"),
      profil: localStorage.getItem("profil"),
      userid: localStorage.getItem("userid"),
    };
    Services.getAllByData("module/list", data)
      .then((res) => {
        var data = res.data.data.data;
        let intevs = [];
        data.forEach((element) => {
          let interv = {
            _id: element?.id,
            name: element?.Titre,
            priority: element["Priorité"],
            datePublication: new Date(element["Date ouverture"]),
            modifiedAt: new Date(element["Date de modification"]),
            descriptif: element["Descriptif"],
            remarque: element["Remarque"],
            solution: element["Solution"],
            explicationministère: element["Explication du ministère"],
            mesurespalliatives: element["Mesures palliatives"],
            type: element["Type"],
            utilisateurConcerne: element["Utilisateur concerné"],
            link: element["Source alerte"],
            files: element?.files == undefined ? [] : element?.files,
          };
          let filess = [];
          let attachments = [];
          interv.files.map((fileuri) => {
            filess.push(convertImageToBase64(fileuri.attachement));
            attachments.push(fileuri.attachement);
          });
          interv.files = filess;
          interv.attachments = attachments;
          intevs.push(interv);
        });
        intevs.sort((a, b) => (a["modifiedAt"] < b["modifiedAt"] ? 1 : -1));
        console.log(intevs);
        setInterventions(intevs);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getListIntervetions();
    SetIsSendRapport(
      profil === Constant.PROFIL_MINISTRE ||
        profil === Constant.PROFIL_PORTE_PAROLAT
    );
  }, []);

  const columns = [
    {
      selector: (row) => row.name,
      //  name: "Nom de l'alerte",
      sortable: false,
      width: "40%",
      //sortIcon: <img src={filterIcon} alt="Sort" />,
    },

    {
      //  name: 'Publiée le',
      selector: (row) => row.datePublication,
      format: (row) => new Date(row.datePublication)?.toLocaleDateString(),
      sortable: true,
    },
    {
      //  name: 'Priorité',
      selector: (row) => row.priority,
      cell: (row) => {
        return (
          <div className={`priority-${row.priority}`}>
            {row.Priorité === "Faible"
              ? "Faible"
              : row.Priorité === "Elevée"
              ? "Elevée"
              : "Moyenne"}
          </div>
        );
      },
      sortable: true,
    },
  ];

  return (
    <Layout>
      <div className="min-vh-100 bg-color rapport container-al" id="rapport">
        <div className=" ">
          <div className="row">
            {isShowTag && (
              <div className="bread-crumb">
                <span className="bread-item">Liste des interventions </span>
                <img src={chevron} alt="chevron"></img>
                <span className="bread-item">Visualiser le rapport</span>
              </div>
            )}
            {isShowTag && (
              <div className="retour pt-26 mb-20">
                <img src={iconBack} alt="iconBack"></img>
                <a href="/interventions" className="retour-link">
                  Retour
                </a>
              </div>
            )}
            <div className="row mt-42">
              <div className="col-md-7">
                <h1>
                  Relevé des alertes majeures postées dans la plateforme interne
                  CICG{" "}
                </h1>
              </div>
              <div className="col-md-5 text-right">
                <button className="btn-secondary-al mx-3">
                  <img
                    src={downloadIcon}
                    alt="mail cion"
                    onClick={downloadRapport}
                  />
                </button>
                {!isSendRapport && (
                  <button className="btn-primary-al">
                    <img src={mailIcon} alt="mail cion" /> Envoyer le rapport
                    par mail
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="bref">
            <span className="date">
              Du {new Date().toLocaleDateString("fr-FR")}
            </span>
          </div>

          {/* <input className="form-control mt-42" type="search" id="search" placeholder="Recherche"/> */}

          {isLoading ? (
            <Loading />
          ) : (
            <div className="container mt-42 bg-primary-al">
              <div className="list-alertes">
                <p className="subTitle">Liste des interventions</p>
                <DatatablesRapport data={interventions} columns={columns} />
              </div>
              {interventions.map((data) => {
                return (
                  <div key={data?._id} className="row mt-48">
                    <div className="col-md-7">
                      <h4>{data.name} </h4>
                      <div className="mt-12">
                        <span>
                          Publié le{" "}
                          {data?.datePublication?.toLocaleDateString("fr-FR")}
                        </span>
                        <span className={`priority-${data.priority} mx-2`}>
                          {data.priority === "Faible"
                            ? "Faible"
                            : data.priority === "Elevée"
                            ? "Elevée"
                            : "Moyenne"}
                        </span>
                      </div>
                      <p
                        className="content-alerte mt-24"
                        dangerouslySetInnerHTML={{ __html: data?.descriptif }}
                      ></p>
                      <hr width="50%" />
                      <h5>Remarque</h5>
                      <p
                        className="content-alerte mt-24"
                        dangerouslySetInnerHTML={{ __html: data?.remarque }}
                      ></p>
                      <hr width="50%" />
                      <h5>Solution</h5>
                      <p
                        className="content-alerte mt-24"
                        dangerouslySetInnerHTML={{ __html: data?.solution }}
                      ></p>
                      <hr width="50%" />
                      <h5>Explication de ministère</h5>
                      <p
                        className="content-alerte mt-24"
                        dangerouslySetInnerHTML={{
                          __html: data?.explicationministère,
                        }}
                      ></p>
                      <hr width="50%" />
                      <h5>Mésure palliatives</h5>
                      <p
                        className="content-alerte mt-24"
                        dangerouslySetInnerHTML={{
                          __html: data?.mesurespalliatives,
                        }}
                      ></p>
                    </div>
                    <div className="col-md-5">
                      <div className="list-alertes">
                        {data.type === "Suivi" && (
                          <section>
                            <span>Pour suivi</span>
                            <p> {data?.utilisateurConcerne}</p>
                            <hr />
                          </section>
                        )}
                        {data.type === "Traitement" && (
                          <section>
                            <span>Pour traitement</span>
                            <p>{data?.utilisateurConcerne}</p>
                          </section>
                        )}
                      </div>
                      <div className="police-alerte mt-24">
                        {data?.attachments &&
                          data?.attachments.length > 0 &&
                          data?.attachments.map((p, key) => {
                            return (
                              <img key={key} src={p} alt="can't show image" />
                            );
                          })}
                        <p className="link">{data?.link}</p>
                      </div>
                    </div>
                    <hr width="70%" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
export default RapportIntervention;
