import React, { useState } from "react";
import "../assets/css/login.css";

import Eye from "../assets/icons/solar_eye-outline.svg";
import Logo from "../assets/icons/logo.png";
import { useNavigate } from "react-router-dom";
import ModalResetPassword from "./../components/resetPassword";
import AuthService from "../services/auth.service";
import Constant from "../utils/constant";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    AuthService.login(email, password).then(
      (Response) => {
        let profil = Response?.data?.profil;
        let role = Response?.data?.role;
        let group = Response?.data?.group;
        let email = Response?.data?.Email;
        let id = Response?.data?.id;
        let menus = Response?.data?.menus;
        let authorizations = Response?.data?.authorizations;
        const expired = new Date();
        localStorage.removeItem("__authorizations");
        expired.setMinutes(expired.getMinutes() + 30);
        localStorage.setItem("__profil", profil);
        localStorage.setItem("__role", role);
        localStorage.setItem("__email", email);
        localStorage.setItem("__id", id);
        localStorage.setItem("__group", group);
        localStorage.setItem("__menus", JSON.stringify(menus));
        localStorage.setItem(
          "__authorizations",
          JSON.stringify(authorizations)
        );
        localStorage.setItem("__expired_myToken", expired);
        if (menus.Alerte == "1") {
          navigate("/alertes");
        } else {
          navigate("/interventions");
        }
      },
      (error) => {
        if (error.response && error.response.status === 400) {
          console.log("password not valide", error.response.data.message);
          setError(error.response.data.message);
          return;
        }
        if (error.response && error.response.status === 404) {
          console.log("Email incorrect");
          setError("user not found");
          return;
        }
        console.log("err", error);
        // Réactiver le bouton de connexion si l'erreur est due à un mot de passe incorrect
        if (error.response && error.response.status === 401) {
          // Réinitialiser l'erreur
          setError("Mot de passe ou email incorrecte");
        }
      }
    );
  };
  const updateIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container-fluid login min-vh-100">
      {isOpen ? (
        <ModalResetPassword isOpen={isOpen} updateIsOpen={updateIsOpen} />
      ) : null}

      <div className=" row">
        <div className="col-md-4 d-flex align-self-center justify-content-center left">
          <img src={Logo} alt="logo" />
        </div>
        <div className="col-md-8 right">
          <div className="form-login ">
            <h1 className="title-login">Se connecter</h1>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="username" className="label-login">
                  E-mail
                </label>
              </div>
              <div>
                <input
                  tabIndex={1}
                  className="form-control"
                  type="email"
                  id="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    setError("");
                  }}
                  placeholder="Adresse e-mail"
                />
              </div>
              <div className="password-field mt-20">
                <label htmlFor="password">Mot de passe</label>
              </div>
              <div className="password-input-container">
                <input
                  tabIndex={1}
                  className="form-control password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                    setError("");
                  }}
                  placeholder="Mot de passe"
                />
                {showPassword ? (
                  <img
                    src={Eye}
                    className="password-icon"
                    alt="password show"
                    onClick={handleToggle}
                  />
                ) : (
                  <img
                    src={Eye}
                    className="password-icon"
                    alt="password-hide"
                    onClick={handleToggle}
                  />
                )}
              </div>

              <div className="float-right pt-10">
                <button
                  tabIndex={4}
                  onClick={() => {
                    updateIsOpen();
                  }}
                  className="cta mt-24"
                >
                  Mot de passe oublié
                </button>
              </div>
              <button
                type="submit"
                className="btn-primary-al w-100 mt-20"
                tabIndex={3}
                autoFocus
              >
                Se connecter
              </button>
              <span className="text-danger pt-5">{error}</span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
