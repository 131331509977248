import Modal from "react-bootstrap/Modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../assets/css/modal.css";
// import Services from "../services/global.service";

import Eye from "../assets/icons/solar_eye-outline.svg";
import { useEffect, useState } from "react";
import AuthService from "../services/auth.service";

// Importing toastify module
import { ToastContainer, toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

const ModalResetPassword = ({ isOpen, updateIsOpen }) => {
  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Champs requis "),
  });

  const onSubmit = (values) => {
    AuthService.resetPassword(values.email)
      .then((res) => {
        if (res?.data?.success === false) {
          toast.error(res?.response?.data?.message);
        } else {
          toast.success("Votre mot de passe à été changé avec succès ");
        }
        setTimeout(function () {
          updateIsOpen(false);
        }, 1000);
        console.log(res);
      })
      .catch((err) => {
        toast.error("Une erreur s'est produite");
      });
  };
  const hideModal = () => {
    updateIsOpen();
  };

  useEffect(() => {}, []);
  return (
    <Modal show={isOpen} onHide={updateIsOpen} className="modal-up">
      <Modal.Header>
        <Modal.Title>
          <h3>Mot de passe oublié</h3>
        </Modal.Title>
        <button className="btn btn-link" onClick={() => updateIsOpen()}>
          {" "}
        </button>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form className=" m-auto">
            <div className=" mt-2">
              <div className="password-field">
                <label htmlFor="pseudo">E-mail</label>
                <div className="password-input-container">
                  <Field
                    name="email"
                    className="form-control"
                    type="email"
                    placeholder="Renseignez votre e-mail"
                  />
                </div>{" "}
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>

            <button type="submit" className="btn-primary-al mt-3 float-right">
              Envoyer
            </button>
            <ToastContainer />
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ModalResetPassword;
