import Layout from "./layout";
import "../assets/css/alertes.css";
import edit from "../assets/icons/cil_pen.svg";
import Warining from "../assets/icons/fluent_warning-32-filled.svg";
import DatatableInterventions from "../components/datatableIntervention";
import { useEffect, useState } from "react";
import Services from "../services/global.service";
import Constant from "../utils/constant";
import Loading from "../components/loading";
import { formatDistance, sub } from "date-fns";
import { fr } from "date-fns/locale";
import ellipse from "../assets/icons/Ellipse1.svg";

function Intervention() {
  const [interventions, setinterventionDatas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [permitUpdateIntervention, setPermitUpdateIntervention] =
    useState(false);

  const profil = localStorage.getItem("__profil");
  const role = localStorage.getItem("__role");

  const columns = [
    {
      selector: (row) => row.Titre,
      name: "Nom de l'intervention",
      sortable: false,
    },

    {
      name: "Priorité",
      selector: (row) => row.Priorité,
      cell: (row) => {
        return (
          <div className={`priority-${row.Priorité}`}>
            {row.Priorité === "Faible"
              ? "Faible"
              : row.Priorité === "Elevée"
              ? "Elevée"
              : "Moyenne"}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Publiée le",
      selector: (row) => row.datePublication,
      format: (row) => new Date(row["Date ouverture"]).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Modifiée",
      selector: (row) => row.modifiedAt,
      format: (row) => new Date(row.modifiedAt).toLocaleDateString(),
      sortable: true,
      cell: (row) => {
        return (
          <p>
            <TimeAgo
              // createdDate={row["Date de création"]}
              updatedDate={row["Date de modification"]}
            />
          </p>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          {permitUpdateIntervention && (
            <a className="cta" href={`/interventions/update/${row.id}`}>
              <img src={edit} alt="icon edit" className="w-75" />
            </a>
          )}
          <a
            className="cta"
            style={{ visibility: "hidden", cursor: "default" }}
          >
            <img src={edit} alt="icon edit" className="w-75" />
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const userInterventionAuthorization = () => {
    let permit = Services.getAuthorization("Update intervention");
    setPermitUpdateIntervention(permit);
  };

  function currentDateTime() {
    var currentDate = new Date();
    let year = currentDate.getUTCFullYear();
    let month = ("0" + (currentDate.getUTCMonth() + 1)).slice(-2); // Adding 1 because getUTCMonth returns 0-indexed month
    let day = ("0" + currentDate.getUTCDate()).slice(-2);
    let hours = ("0" + currentDate.getUTCHours()).slice(-2);
    let minutes = ("0" + currentDate.getUTCMinutes()).slice(-2);
    let seconds = ("0" + currentDate.getUTCSeconds()).slice(-2);

    // Format the date string
    let gmtDateString =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
    return gmtDateString;
  }

  const TimeAgo = ({ updatedDate }) => {
    // Utilisez formatDistanceToNow pour obtenir la différence formatée
    let now = currentDateTime();
    console.log("Date de modification", new Date(updatedDate));
    console.log("Date actuelle", new Date(now));
    const timeLeft = formatDistance(new Date(updatedDate), new Date(now), {
      addSuffix: true,
      locale: fr,
    });

    return (
      <span className="modified-txt">
        <img src={ellipse} alt="ellipse" className="mx-2" />
        {timeLeft}
      </span>
    );
  };

  const tabsProfil = () => {
    let tabss = [
      { value: "Suivi", name: "Pour suivi" },
      { value: "Traitement", name: "Pour traitement" },
    ];
    if (
      role === Constant.ROLE_RESPO_CICG ||
      role === Constant.ROLE_AGENT_CICG
    ) {
      tabss.push({ value: "Fermé", name: "A Clôturer" });
    }
    tabss.push({ value: "all", name: "Toutes Les Interventions" });
    setTabs(tabss);
  };

  const getListIntervetions = () => {
    setIsLoading(true);
    const data = {
      setup: "gouvalert2",
      moduleName: "Intervention",
      role: localStorage.getItem("role"),
      group: localStorage.getItem("group"),
      profil: localStorage.getItem("profil"),
      userid: localStorage.getItem("userid"),
    };
    Services.getAllByData("module/list", data)
      .then((res) => {
        var data = res.data.data;
        data.sort((a, b) =>
          new Date(a["Date de modification"]) <
          new Date(b["Date de modification"])
            ? 1
            : -1
        );
        setinterventionDatas(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getListIntervetions();
    userInterventionAuthorization();
    tabsProfil();
  }, []);

  return (
    <Layout>
      <div className="min-vh-100 bg-color container-al">
        <div className="">
          <div className="row">
            <div className="col-md-6">
              <h1>Liste des interventions </h1>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-end">
              {/* <a
                className="btn-secondary-al mx-2"
                href="/interventions/rapport"
              >
                Visualiser le rapport
              </a> */}
              {/* { (
                <button className="btn-primary-al mx-2">
                  Transmettre le rapport
                </button>
              ) } */}
            </div>
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <div className="container mt-42 bg-primary-al">
              <DatatableInterventions
                data={interventions}
                tabs={tabs}
                columns={columns}
                path={`interventions/details`}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
export default Intervention;
