import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import closeIcon from "../assets/icons/close-outline.svg";
// import '../assets/css/modal.css';
const ChangePasswordModal = ({
  isOpenDelete,
  setIsOpenDelete,
  hideModal,
  DeleteFunction,
  title,
}) => {
  function confirmDelete() {
    DeleteFunction();
    setIsOpenDelete(false);
  }
  return (
    <Modal show={isOpenDelete} onHide={hideModal} className="modal-up">
      <ModalBody>
        <button
          className="btn btn-link float-right"
          onClick={() => setIsOpenDelete(false)}
        >
          <img src={closeIcon} alt="close icon" />{" "}
        </button>
        <h5 className="text-center"> {title} </h5>
        <div className="row pt-20">
          <div className="col">
            <button
              className="btn-danger-al float-right"
              onClick={confirmDelete}
            >
              Oui
            </button>
          </div>
          <div className="col">
            <button
              className="btn-secondary-al "
              onClick={() => {
                setIsOpenDelete(false);
              }}
            >
              Non
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ChangePasswordModal;
