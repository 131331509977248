import React from 'react';
import { useDropzone } from 'react-dropzone';

const FileDropzone = ({ onDrop,droppedFiles }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
      <input {...getInputProps()} />
      <p>Drag and drop files here, or click to select files</p>
      {droppedFiles.length > 0 && (
            <div>
           
                {droppedFiles.map((file) => (
                <h5>{file.name}</h5>
                ))}
            
            </div>
        )}
    </div>
  );
};

export default FileDropzone;
