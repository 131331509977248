import Layout from "./layout";
import "../assets/css/alertes.css";
import { useState } from "react";
import Warining from "../assets/icons/fluent_warning-32-filled.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
function Dashboard() {
  const percentage = 100;
  const percentage2 = 100;
  const percentage3 = 100;

  return (
    <Layout>
      <div className="min-vh-100 bg-color container-al">
        <div className="">
          <div className="row">
            <div className="col-md-6">
              <h1>Dashboard </h1>
            </div>
          </div>
          {/* <input className="form-control mt-42" type="search" id="search" placeholder="Recherche"/> */}

          <div className="container mt-42 bg-primary-al">
            <div className="row justify-content-around">
              <div className="col-1"></div>
              <div className="col-md-3">
                <CircularProgressbar
                  maxValue={100}
                  value={percentage}
                  className="d-flex m-auto"
                  text={`${percentage}`}
                  styles={buildStyles({
                    // Colors
                    pathColor: `rgba(26, 156, 56, ${percentage / 100})`,
                    textColor: "#ef7b24",
                    trailColor: "#d6d6d6",
                  })}
                />
                <h5 className="mt-24 text-center">
                  Nombre d'interventions en attente d'appréciation
                </h5>
              </div>

              <div className="col-md-3">
                <CircularProgressbar
                  maxValue={100}
                  value={percentage2}
                  text={`720`}
                  className="d-flex m-auto"
                  styles={buildStyles({
                    // Colors
                    pathColor: `rgba(26, 156, 56, ${percentage2 / 100})`,
                    textColor: "#ef7b24",
                    trailColor: "#d6d6d6",
                  })}
                />
                <h5 className="mt-24 text-center">
                  Nombre d'interventions pour traitement
                </h5>
              </div>

              <div className="col-md-3">
                <CircularProgressbar
                  maxValue={100}
                  value={percentage3}
                  text={`360`}
                  className="d-flex m-auto"
                  styles={buildStyles({
                    // Colors
                    pathColor: `rgba(26, 156, 56, ${percentage3 / 100})`,
                    textColor: "#ef7b24",
                    trailColor: "#d6d6d6",
                  })}
                />
                <h5 className="mt-24 text-center">
                  Nombre d'interventions pour suivi
                </h5>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default Dashboard;
