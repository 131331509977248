const SETUP = "gouvalert2";
const HELPDESK = "HelpDesk";
const INTERVENTION = "Intervention";
const USERS = "Users";
const ETAT_A_TRANSMETTRE = "A transmettre";
const PRIORITY_ELEVE = "Elevée";
const PRIORITY_MOYENNE = "Moyenne";
const PRIORITY_FAIBLE = "Faible";
const PROFIL_AGENTCICG = "2";
const PROFIL_RESPOCICG = "6";
const PROFIL_PRIMATURE = "8";
const PROFIL_PORTE_PAROLAT = "5";
const PROFIL_MINISTRE = "9";
const PROFIL_DIR_CAB = "4";
const PROFIL_POINTFOCAL = "3";
const ROLE_RESPO_CICG = "44x2060";
const ROLE_AGENT_CICG = "44x2061";

const Constant = {
  SETUP,
  HELPDESK,
  INTERVENTION,
  USERS,
  PROFIL_AGENTCICG,
  PROFIL_RESPOCICG,
  PROFIL_PRIMATURE,
  PROFIL_PORTE_PAROLAT,
  PROFIL_MINISTRE,
  PROFIL_DIR_CAB,
  PROFIL_POINTFOCAL,
  ETAT_A_TRANSMETTRE,
  PRIORITY_ELEVE,
  PRIORITY_MOYENNE,
  PRIORITY_FAIBLE,
  ROLE_RESPO_CICG,
  ROLE_AGENT_CICG,
};
export default Constant;
