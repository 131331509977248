import Layout from "./layout";
import "../assets/css/rapport.css";
import { useEffect, useState } from "react";
import Warining from "../assets/icons/fluent_warning-32-filled.svg";
import Datatables from "../components/datatable";
import { useParams } from "react-router";
import chevron from "../assets/icons/ep_arrow-up.svg";
import iconBack from "../assets/icons/mingcute_arrow-up-line.svg";
import mailIcon from "../assets/icons/ion_mail-outline.svg";
import downloadIcon from "../assets/icons/lucide_download.svg";
import mediumIcon from "../assets/icons/fluent_info-24-regular.svg";
import lowIcon from "../assets/icons/fluent_info-24-regular-low.svg";
import highIcon from "../assets/icons/fluent_info-24-regular-high.svg";
import Services from "../services/global.service";
import Loading from "../components/loading";
import Constant from "../utils/constant";

function DetailsIntervention() {
  const [detail, setDetails] = useState([]);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const params = useParams();
  const idAlerte = params.id;

  const profil = localStorage.getItem("__profil");

  const getDetails = () => {
    setIsLoading(true);
    const data = {
      setup: "gouvalert2",
      recordId: idAlerte,
      moduleName: "Intervention",
      role: localStorage.getItem("userid"),
    };
    Services.getAllByData("module/detail", data)
      .then((res) => {
        var resData = res?.data?.data?.Resume[0];
        const data = {
          id: resData.id,
          Titre: resData.Titre,
          Type: resData.Type,
          user_concern: resData["Utilisateur concerné"],
          Priorité: resData["Priorité"],
          datePublication: new Date(
            resData["Date ouverture"]
          ).toLocaleDateString(),
          modifiedAt: new Date(
            resData["Date de modification"]
          ).toLocaleDateString(),
          status: resData["Statut"],
          url: resData["Source alerte"],
          Remarque: resData["Remarque"],
          Solution: resData["Solution"],
          Résumésituation: "",
          Explicationministère: resData["Explication du ministère"],
          Mesurespalliatives: "",
          documents: resData?.documents,
          Descriptif: resData["Descriptif"],
        };
        setDetails(data);
        checkUpdateIntervention(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const checkUpdateIntervention = (data) => {
    let isCheck = false;
    console.log(profil,data?.Type)
    if (profil === Constant.PROFIL_RESPOCICG) {
      isCheck = true;
    } else if (
      profil === Constant.PROFIL_POINTFOCAL &&
      data?.Type === "Traitement"
    ) {
      isCheck = true;
    }
    setIsUpdate(isCheck);
  };
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <Layout>
      <div className="min-vh-100 bg-color container-al rapport" id="rapport">
        <div className="">
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              <div className="row">
                <div className="bread-crumb">
                  <span className="bread-item">Liste des interventions </span>
                  <img src={chevron} alt="chevron"></img>
                  <span className="bread-item">Détails intervention</span>
                </div>
                <div className="retour pt-26 mb-20">
                  <img src={iconBack} alt="iconBack"></img>
                  <a href="/interventions" className="retour-link">
                    Retour
                  </a>
                </div>

                <div className="row mt-42">
                  <div className="col-md-7">
                    <h1>{detail?.Titre} </h1>
                  </div>
                  {isUpdate && (
                    <div className="col-md-5">
                      <a
                        href={`/interventions/update/${detail?.id}`}
                        className="btn-primary-al float-right"
                      >
                        {" "}
                        Modifier
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="bref">
                <span className="date">
                  {new Date().toLocaleDateString("fr-FR")}
                </span>
                <span className={`priority-${detail?.Priorité} mx-2`}>
                  {detail?.Priorité === "Faible"
                    ? "Faible"
                    : detail?.Priorité === "Elevée"
                    ? "Elevée"
                    : "Moyenne"}
                  {detail?.Priorité === "Faible" ? (
                    <img
                      className="mx-2"
                      src={lowIcon}
                      alt="icon low priority"
                    />
                  ) : detail?.Priorité === "Elevée" ? (
                    <img
                      className="mx-2"
                      src={highIcon}
                      alt="icon low priority"
                    />
                  ) : (
                    <img
                      src={mediumIcon}
                      className="mx-2"
                      alt="icon medium priority"
                    />
                  )}
                </span>
              </div>

              {/* <input className="form-control mt-42" type="search" id="search" placeholder="Recherche"/> */}

              <div className="container mt-42 bg-primary-al">
                <div className="row mt-48">
                  <div className="col-md-7">
                    <p
                      className="content-alerte mt-24"
                      dangerouslySetInnerHTML={{ __html: detail?.Descriptif }}
                    ></p>
                    <hr width="50%" />
                    <h5>Remarque</h5>
                    <p
                      className="content-alerte mt-24"
                      dangerouslySetInnerHTML={{ __html: detail?.Remarque }}
                    ></p>
                    <hr width="50%" />
                    <h5>Solution</h5>
                    <p
                      className="content-alerte mt-24"
                      dangerouslySetInnerHTML={{ __html: detail?.Solution }}
                    ></p>
                    <hr width="50%" />
                    <h5>Explication de ministère</h5>
                    <p
                      className="content-alerte mt-24"
                      dangerouslySetInnerHTML={{
                        __html: detail?.Explicationministère,
                      }}
                    ></p>
                    <hr width="50%" />
                    <h5>Mésure palliatives</h5>
                    <p
                      className="content-alerte mt-24"
                      dangerouslySetInnerHTML={{
                        __html: detail?.Mesurespalliatives,
                      }}
                    ></p>
                  </div>
                  <div className="col-md-5">
                    <div className="list-alertes">
                      {detail?.Type == "Suivi" && (
                        <div>
                          <span>Pour suivi</span>
                          <p>{detail?.user_concern}</p>
                        </div>
                      )}
                      {detail?.Type == "Traitement" && (
                        <div>
                          <span>Pour traitement</span>
                          <p>{detail?.user_concern}</p>
                        </div>
                      )}
                      <hr />
                    </div>
                    <div className="police-alerte mt-24">
                      {detail?.documents &&
                        detail?.documents.length > 0 &&
                        detail?.documents.map((p, key) => {
                          return (
                            <img
                              key={key}
                              src={p.attachement}
                              alt="can't show image"
                            />
                          );
                        })}
                      <br />
                      <p className="link">{detail?.url}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
export default DetailsIntervention;
