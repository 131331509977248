import DataTable from "react-data-table-component";
import "../App.css";
import { useEffect, useMemo, useState } from "react";

export default function Datatables({ data, columns, path, tabs }) {
  const [filterText, setFilterText] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    tabs.length > 0 ? tabs[0].value : "all"
  );

  const customStyles = {
    table: {
      style: {
        backgroundColor: "#FCFCFC",
        fontSize: "16px",
        // border: "0.5px solid #DEDEDE",
        borderRadius: "10px",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#F4F3F3",
        // border: "0.5px solid #FCFCFC",
        // borderRadius:"10px 10px 0 0",
        // borderBottomStyle:"hidden",
        fontSize: "16px",
        fontFamily: "DM-Sans-Medium",
        fontWeight: "600",
        //  marginLeft:'10px',
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        backgroundColor: "#fff",
        // borderRadius:"20px",
        // margin:"10px",
        // width:'98%',
        // boxShadow:"0px 4px 20px rgba(0, 0, 0, 0.1)",
        borderBottomStyle: "#DEDEDE",
        borderBottomWidth: "90%",
        fontSize: "16px",
        fontFamily: "DM-Sans-Regular",
        minHeight: "64px",
      },
    },
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Lignes par page",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tous",
  };
  const handleRowClick = (row) => {
    // Redirect to the detail event page using the row id
    window.location.href = `${path}/${row.id}`;
  };
  const filterData = () => {
    let tempFilteredData = data;
    let userid = localStorage.getItem("__id");
    if (!Array.isArray(tempFilteredData)) {
      // Handle the case when data is not an array (or undefined)
      tempFilteredData = [];
    }

    if (currentTab !== "all") {
      tempFilteredData = tempFilteredData?.filter(
        (item) => item?.Etat === currentTab
      );
      if (currentTab === "En cours" || currentTab === "Ne pas transmettre") {
        tempFilteredData = tempFilteredData?.filter(
          (item) => item["Assigné à"] === userid
        );
      }
    } else {
      tempFilteredData = tempFilteredData?.filter(
        (item) =>
          item?.Etat !== "En cours" && item?.Etat !== "Ne pas transmettre"
      );
    }

    if (filterText) {
      tempFilteredData = tempFilteredData?.filter(
        (item) =>
          JSON.stringify(item)
            .toLowerCase()
            .indexOf(filterText.toLowerCase()) !== -1
      );
    }

    setFilteredItems(tempFilteredData);
  };

  useEffect(() => {
    filterData();
  }, [currentTab, data, filterText]);

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };

    return (
      <>
        <div className="col-md-12 ">
          <input
            className="form-control"
            id="search"
            type="text"
            placeholder="Recherche"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
        <div className="col-md-12 tabs-container mt-38">
          {tabs?.map((el) => {
            return (
              <div
                className={`tab ${currentTab === el.value ? "active" : ""}`}
                onClick={() => setCurrentTab(el?.value)}
              >
                <a>
                  {el?.name}&nbsp;
                  {currentTab === el.value && (
                    <span className="badge bg-success">
                      {filteredItems.length}
                    </span>
                  )}
                </a>
              </div>
            );
          })}
        </div>
      </>
    );
  }, [filterText, currentTab, filteredItems]);

  const handleSelectedRowsChange = (selectedRows) => {
    const selectedIndexes = selectedRows.selectedRows.map((row) => row._id);
    setSelectedRows(selectedIndexes);
  };

  return (
    <>
      <div className="col-md-12 mt-24">
        {data && (
          <DataTable
            columns={columns}
            data={filteredItems}
            className="datatable-all mt-24"
            pagination
            paginationComponentOptions={paginationComponentOptions}
            customStyles={customStyles}
            onRowClicked={(row) => {
              if (path !== "") {
                handleRowClick(row);
              }
            }}
            subHeader
            subHeaderComponent={subHeaderComponent}
            onSelectedRowsChange={handleSelectedRowsChange}
          />
        )}
      </div>
    </>
  );
}
