import Layout from "./layout";
import '../assets/css/rapport.css'
import { useState } from "react";
import Warining from '../assets/icons/fluent_warning-32-filled.svg'
import downloadIcon from '../assets/icons/lucide_download.svg';
import Datatables from "../components/datatable";

function Rapports(){
    const rapports = [
        {
         id:"123",
          name: "Rapport 1",
          dateGeneration: new Date("2023-10-03T08:00:00Z"),
          dateEnvoi: new Date("2023-10-03T10:30:00Z"),
          Statut: "generated",
        },
        {
          id:"124",
          name: "Rapport 2",
          dateGeneration: new Date("2023-10-03T09:15:00Z"),
          dateEnvoi: new Date("2023-10-03T11:45:00Z"),
          Statut: "sended",
        },
        {
          id:"125",
          name: "Rapport 3",
          dateGeneration: new Date("2023-10-03T10:30:00Z"),
          dateEnvoi: new Date("2023-10-03T12:00:00Z"),
          Statut: "generated",
        },
      ];
      const columns = [
   
        {
          selector: (row) =>row.name,
          name: "Nom du rapport",
          sortable: false,
          //sortIcon: <img src={filterIcon} alt="Sort" />,
        },
       
        
        
        {
            name: 'Généré le',
            selector: (row) =>row.dateGeneration ,
            format: (row) => new Date(row.dateGeneration).toLocaleDateString(),
            sortable: true
          },
        {
            name: 'Envoyé le',
            selector:  (row) =>row.dateEnvoi,
            format: (row) => new Date(row.dateEnvoi).toLocaleDateString(),
            sortable: true
          },
        {
          name: 'Statut',
          selector:  (row) =>row.Statut,
          cell: (row) =>
          {
            return (
                <div className={`status-${row.Statut}`}>{row.Statut==="generated"? "Généré":"Envoyé"}</div>
             );
            
          },

          sortable: true
        },
        {
          name: '',
         selector:  (row) =>row.status,
          cell: (row) =>
          {
            return (
               <button className="btn-secondary-al" onClick={()=>{console.log(row._id)}}> <img src={downloadIcon} alt="download icon" className="mx-2"/></button>
             );
            
          },
          sortable: true
        },
       
      ];


    return (
        <Layout>
            <div className="min-vh-100 bg-color container-al">
                <div className="">
                    <div className="row">
                        <div className="col-md-12"><h1>Liste des rapports</h1></div>
                        {/* <div className="col-md-6 text-right">
                            <a className="btn-secondary-al mx-3" href="/alertes/rapport">Visualiser le rapport</a>
                            <button className="btn-primary-al" >Transmettre le rapport</button>
                        </div> */}
                    </div>  
                    {/* <input className="form-control mt-42" type="search" id="search" placeholder="Recherche"/> */}

                   
                    <div className="container mt-42 bg-primary-al">
                        <Datatables
                        data={rapports}
                        tabs={[{"value":"sended","name":"A envoyer"},{"value":"generated","name":"Générés"},{"value":"all","name":"Tous Les rapports"}]}
                        columns={columns}
                        path={`/alertes/rapport`}/>
                    </div>
                </div>  
            </div>
          
        </Layout>
    );
}
export default Rapports;