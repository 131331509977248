import Layout from "./layout";
import "../assets/css/rapport.css";
import { useEffect, useState } from "react";
import Warining from "../assets/icons/fluent_warning-32-filled.svg";
import Datatables from "../components/datatable";
import { useParams } from "react-router";
import chevron from "../assets/icons/ep_arrow-up.svg";
import iconBack from "../assets/icons/mingcute_arrow-up-line.svg";
import mailIcon from "../assets/icons/ion_mail-outline.svg";
import downloadIcon from "../assets/icons/lucide_download.svg";
import mediumIcon from "../assets/icons/fluent_info-24-regular.svg";
import lowIcon from "../assets/icons/fluent_info-24-regular-low.svg";
import highIcon from "../assets/icons/fluent_info-24-regular-high.svg";
import urlData from "../assets/uploads/upload1.png";
import Services from "../services/global.service";
import Constant from "../utils/constant";
import Loading from "../components/loading";

function DetailsAlerte() {
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [groups, setGroups] = useState([]);
  const [treatUsers, setTreatUsers] = useState([]);
  const [suiviUsers, setSuiviUsers] = useState([]);
  const params = useParams();
  const idAlerte = params.id;

  const getDetails = () => {
    setIsLoading(true);
    const data = {
      setup: Constant.SETUP,
      recordId: idAlerte,
      moduleName: Constant.HELPDESK,
      role: localStorage.getItem("__role"),
    };
    Services.getAllByData("module/detail", data)
      .then((res) => {
        let detail = res?.data?.data?.Resume[0];
        setDetails(detail);
        Services.getAll("groups/" + Constant.SETUP).then((response) => {
          let groups = response?.data?.data;
          if (detail && detail["Utilisateurs pour suivi"] !== undefined) {
            getUsersGroups(groups, detail["Utilisateurs pour suivi"], "suivi");
          }
          if (detail && detail["Utilisateurs pour traitement"] !== undefined) {
            getUsersGroups(
              groups,
              detail["Utilisateurs pour traitement"],
              "traitement"
            );
          }
          setIsLoading(false);
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getUsersGroups = (groups, ids, type) => {
    let usergroups = [];
    if (ids.length > 0) {
      ids.forEach((id) => {
        let result = groups.find((item) => item.id === id);
        if (result !== undefined) {
          usergroups.push(result.value);
        }
      });
      if (type == "suivi") {
        setSuiviUsers(usergroups);
      } else {
        setTreatUsers(usergroups);
      }
      console.log("groups: " + usergroups);
      console.log("type: " + type);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  return (
    <Layout>
      <div className="min-vh-100 bg-color container-al rapport" id="rapport">
        <div className="">
          <div className="row">
            <div className="bread-crumb">
              <span className="bread-item">Liste des Alertes </span>
              <img src={chevron} alt="chevron"></img>
              <span className="bread-item">Détails alerte</span>
            </div>
            <div className="retour pt-26 mb-20">
              <img src={iconBack} alt="iconBack"></img>
              <a href="/alertes" className="retour-link">
                Retour
              </a>
            </div>
            {!isLoading ? (
              <div className="row mt-42">
                <div className="col-md-7">
                  <h1>{details?.Titre} </h1>
                </div>
                <div className="col-md-5">
                  <a
                    href={`/alertes/update/${details?.id}`}
                    className="btn-primary-al float-right"
                  >
                    {" "}
                    Modifier
                  </a>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              {" "}
              <div className="bref">
                <span className="date">
                  {new Date(details["Date de création"]).toLocaleDateString(
                    "fr-FR"
                  )}
                </span>
                <span className={`priority-${details?.Priorité} mx-2`}>
                  {details?.Priorité === "Faible"
                    ? "Faible"
                    : details?.Priorité === "Elevée"
                    ? "Elevée"
                    : "Moyenne"}
                  {details?.Priorité === "Faible" ? (
                    <img
                      className="mx-2"
                      src={lowIcon}
                      alt="icon low priority"
                    />
                  ) : details?.Priorité === "Elevée" ? (
                    <img
                      className="mx-2"
                      src={highIcon}
                      alt="icon low priority"
                    />
                  ) : (
                    <img
                      src={mediumIcon}
                      className="mx-2"
                      alt="icon medium priority"
                    />
                  )}
                </span>
              </div>
              {/* <input className="form-control mt-42" type="search" id="search" placeholder="Recherche"/> */}
              <div className="container mt-42 bg-primary-al">
                <div className="row mt-48">
                  <div className="col-md-7">
                    <p
                      className="content-alerte mt-24"
                      dangerouslySetInnerHTML={{ __html: details?.Descriptif }}
                    ></p>
                  </div>
                  <div className="col-md-5">
                    <div className="list-alertes">
                      <span>Pour suivi</span>
                      {suiviUsers.map((p, key) => {
                        return <p key={key}>{p}</p>;
                      })}
                      <hr />
                      <span>Pour traitement</span>
                      {treatUsers.map((p, key) => {
                        return <p key={key}>{p}</p>;
                      })}
                    </div>
                    <div className="police-alerte mt-24">
                      {details?.documents &&
                        details?.documents.length > 0 &&
                        details?.documents.map((p, key) => {
                          return (
                            <img
                              key={key}
                              src={p.attachement}
                              alt="can't show image"
                            />
                          );
                        })}
                      <p className="link">{details["Source alerte"]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
export default DetailsAlerte;
