import React from "react";

import "../assets/css/message.css";
import { fr } from "date-fns/locale";
import { formatDistance, sub } from "date-fns";
import ellipse from "../assets/icons/Ellipse1.svg";

function Message({ messages }) {
 
  function currentDateTime() {
    var currentDate = new Date();
    let year = currentDate.getUTCFullYear();
    let month = ("0" + (currentDate.getUTCMonth() + 1)).slice(-2); // Adding 1 because getUTCMonth returns 0-indexed month
    let day = ("0" + currentDate.getUTCDate()).slice(-2);
    let hours = ("0" + currentDate.getUTCHours()).slice(-2);
    let minutes = ("0" + currentDate.getUTCMinutes()).slice(-2);
    let seconds = ("0" + currentDate.getUTCSeconds()).slice(-2);

    // Format the date string
    let gmtDateString =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
    return gmtDateString;
  }

  const TimeAgo = ({ updatedDate }) => {
    // Utilisez formatDistanceToNow pour obtenir la différence formatée
    let now = currentDateTime();
    const timeLeft = formatDistance(new Date(updatedDate), new Date(now), {
      addSuffix: true,
      locale: fr,
    });

    return (
      <span className="modified-txt">
        <img src={ellipse} alt="ellipse" className="mx-2" />
        {timeLeft}
      </span>
    );
  };

  return (
    <div class="col-md-8">
      <div class="chat_container">
        <div class="job-box">
          <div class="inbox-message">
            {messages !== null && (
              <ul>
                {messages.map((message) => (
                  <li key={message.id}>
                    <a href="#">
                      <div class="message-avatar">
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar7.png"
                          alt=""
                        />
                      </div>
                      <div class="message-body">
                        <div class="message-body-heading">
                          <h5>{message["Nom"]}</h5>
                          <span>
                            <TimeAgo updatedDate={message["Modified At"]} />
                          </span>
                        </div>
                        <p>{message["Description"]}</p>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;
