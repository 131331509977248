import Layout from "./layout";
import "../assets/css/alertes.css";
import Warining from "../assets/icons/fluent_warning-32-filled.svg";
import UserForm from "../components/formUser";
import chevron from "../assets/icons/ep_arrow-up.svg";
import iconBack from "../assets/icons/mingcute_arrow-up-line.svg";
function CreateUser() {
  return (
    <Layout>
      <div className="min-vh-100 bg-color container-al">
        <div className=" ">
          <div className="bread-crumb">
            <span className="bread-item">Liste des Utilisateurs </span>
            <img src={chevron} alt="chevron"></img>
            <span className="bread-item">Créer Utilisateurs</span>
          </div>
          <div className="retour pt-26 mb-20">
            <img src={iconBack} alt="iconBack"></img>
            <a href="/users" className="retour-link">
              Retour
            </a>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h1>Créer Utilisateur </h1>
            </div>

            <hr></hr>
          </div>
          <UserForm />
        </div>
      </div>
    </Layout>
  );
}
export default CreateUser;
