import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {

  const expired = localStorage.getItem("__expired_myToken");
  const userId = localStorage.getItem("__id");
  const now = Date.now();
  const current = new Date(expired).getTime();


  if (now < current && userId && userId !== undefined) {
      return children;
  } else {
    localStorage.clear();
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
