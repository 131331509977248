import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileDropzone from "./fileDropZone";
import "../assets/css/alertes.css";
import Services from "../services/global.service";
import Loading from "../components/loading";
import Constant from "../utils/constant";
import { useNavigate } from "react-router-dom";
import Message from "./Message";

const InterventionFormUpdate = () => {
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [etatOptions, setEtatOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [saveResult, setSaveResult] = useState(0);
  const [newMessage, setNewMessage] = useState(0);
  const [permitInterventionComments, setPermitInterventionComments] =
    useState(false);
  const [messages, setMessages] = useState([]);

  const [initialValues, setInitialValues] = useState({
    priority: "",
    status: "",
    title: "",
    remarque: "",
    solution: "",
    openingDate: new Date(),
    closingDate: new Date(),
    resumeSituation: "",
    explicationMinistere: "",
    mesurepalliatives: "",
    traitementMedia: "",
    state: "",
    source: "",
    description: "",
    alerte: "",
    recommandation: ".",
    trackingUsers: [],
    traitementUser: [],
    attachments: [],
  });

  const params = useParams();
  const navigate = useNavigate();
  const idAlerte = params.id;
  const groupsUsers = [];
  const profil = localStorage.getItem("__profil");

  const getDataInputs = () => {
    setIsLoading(true);
    Services.getDataSelectedInput()
      .then(
        axios.spread(
          (
            { data: priority },
            { data: status },
            { data: etats },
            { data: groups } //{data:categories},
          ) => {
            ////get priority
            let priorities = [];
            priority?.data?.forEach((element) => {
              var p = { value: element, label: element };
              priorities.push(p);
            });
            setPriorityOptions(priorities);
            // ////get status
            let sts = [];
            status?.data?.forEach((st) => {
              if (profil === Constant.PROFIL_POINTFOCAL && st !== "Résolu") {
                var s = { value: st, label: st };
                sts.push(s);
              } else {
                var s = { value: st, label: st };
                sts.push(s);
              }
            });
            setStatusOptions(sts);
            // ////get etats
            let ets = [];
            etats?.data?.forEach((et) => {
              var e = { value: et, label: et };
              ets.push(e);
            });
            setEtatOptions(ets);
            // ////get users groups
            // let groupes = []
            groups?.data?.forEach((gp) => {
              var g = { value: gp["id"], label: gp["value"] };
              groupsUsers.push(g);
            });
            setUsersOptions(groupsUsers);
            setIsLoading(false);
          }
        )
      )
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleComment = (e) => {
    let mess = e.target.value;
    console.log(mess);
    if (mess.length !== 0) {
      setNewMessage(mess);
    }
  };

  const handleSubmitComment = async () => {
    const profil = localStorage.getItem("__profil");
    const role = localStorage.getItem("__role");
    const nom =
      localStorage.getItem("nom") + " " + localStorage.getItem("prenom");
    const group = localStorage.getItem("group");
    const userid = localStorage.getItem("userid");
    if (idAlerte.length == 0 || newMessage.length == 0) {
      return;
    }
    const data = {
      setup: Constant.SETUP,
      intervention: idAlerte,
      role: role,
      profil: profil,
      nom: nom,
      group: group,
      userid: userid,
      description: newMessage,
    };
    let response = await Services.create(data, "comments/add");
    if (response?.data?.success) {
      window.location.reload();
    }
  };

  const getUsersGroups = (data) => {
    let groups = [];
    let result = groupsUsers.find((item) => item.label === data);
    if (result) {
      groups.push(result);
    }
    return groups;
  };

  const userInterventionCommentsAuthorization = () => {
    let permit = Services.getAuthorization("Commentaires");
    setPermitInterventionComments(permit);
  };

  const getDetails = () => {
    setIsLoading(true);
    const data = {
      setup: Constant.SETUP,
      recordId: idAlerte,
      moduleName: Constant.INTERVENTION,
      role: localStorage.getItem("__role"),
    };
    Services.getAllByData("module/detail", data)
      .then((res) => {
        let detail = res?.data?.data?.Resume[0];
        console.log(detail);
        let values = {
          priority: detail["Priorité"],
          status: detail?.Statut,
          title: detail?.Titre,
          remarque: detail?.Remarque,
          solution: detail?.Solution,
          openingDate: new Date(detail["Date ouverture"]),
          closingDate:
            detail["Date de fermeture"].length == 0
              ? ""
              : new Date(detail["Date de fermeture"]),
          resumeSituation: detail["Résumé de la situation"],
          explicationMinistere: detail["Explication du ministère"],
          mesurePalliatives: detail["Mesures palliatives"],
          traitementMedia: detail["Traitement média"],
          state: "",
          alerte: detail?.Alerte,
          source: detail["Source alerte"],
          description: detail?.Descriptif,
          recommandation: detail["Recommandation CICG"],
          trackingUsers:
            detail["Type"] === "Suivi"
              ? getUsersGroups(detail["Utilisateur concerné"])
              : "",
          traitementUser:
            detail["Type"] === "Traitement"
              ? getUsersGroups(detail["Utilisateur concerné"])
              : "",
          attachments: [],
        };
        setInitialValues(values);
        console.log("comments", detail["commentaires"]);
        setMessages(detail["commentaires"]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const handleDrop = (acceptedFiles) => {
    // Handle the dropped files here
    console.log("Accepted Files:", acceptedFiles);
    setDroppedFiles(acceptedFiles);
  };

  const validationSchema = Yup.object().shape({
    // Define your validation schema here
    // title: Yup.string().required('Title is required'),
    // openingDate: Yup.date().required('Opening Date is required'),
    // description: Yup.string().required('Description is required'),
  });

  useEffect(() => {
    getDataInputs();
    getDetails();
    userInterventionCommentsAuthorization();
  }, []);

  const onSubmit = async (values, { resetForm }) => {
    console.log(values);
    // return
    try {
      setIsLoading(true);
      var files = [];
      if (droppedFiles.length > 0) {
        let uploadData = new FormData();
        droppedFiles.forEach((file) => {
          uploadData.append("documents[]", file);
        });
        files = await Services.create(uploadData, "upload/documents", true);
      }
      const object = {
        id: idAlerte,
        Alerte: values.alerte,
        "Explication du ministère": values.explicationMinistere,
        "Mesures palliatives": values.mesurePalliatives,
        "Recommandation CICG": values.recommandation,
        "Date de fermeture": values.closingDate,
        "Traitement média": values.traitementMedia,
        "Résumé de la situation": values.resumeSituation,
        Remarque: values.remarque,
        Solution: values.solution,
        Statut: values.status.value,
        documents: droppedFiles.length > 0 ? files?.data : files,
      };
      let formData = {
        setup: Constant.SETUP,
        moduleName: Constant.INTERVENTION,
        object: object,
        userid: localStorage.getItem("__id"),
      };
      let response = await Services.create(formData, "module/save");
      if (response?.data?.success) {
        setSaveResult(1);
        resetForm({ values: "" });
        setDroppedFiles([]);
      } else {
        setSaveResult(2);
      }
      setIsLoading(false);
      navigate("/interventions");
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div>
      {saveResult === 1 ? (
        <div className="alert alert-success" role="alert">
          Intervention a été modifiée avec succès
        </div>
      ) : saveResult == 2 ? (
        <div className="alert alert-danger" role="alert">
          Une erreur s'est produite.Veuillez réessayer ultérieurement
        </div>
      ) : null}
      <form onSubmit={formik.handleSubmit}>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <div className="row mt-42">
              {/* Priority */}
              <div className="col">
                <label htmlFor="priority">Priorité</label>
                <Select
                  id="priority"
                  name="priority"
                  value={priorityOptions.find(function (option) {
                    return option.value === formik.values.priority;
                  })}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("priority", selectedOption)
                  }
                  options={priorityOptions}
                  placeholder="Sélectionner..."
                  isDisabled
                />
                {formik.errors.priority && formik.touched.priority && (
                  <div className="text-danger">{formik.errors.priority}</div>
                )}
              </div>

              {/* Status */}
              <div className="col">
                <label htmlFor="status">Statut</label>
                <Select
                  id="status"
                  placeholder="Sélectionner..."
                  name="status"
                  value={statusOptions.find(function (option) {
                    return option.value === formik.values.status;
                  })}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("status", selectedOption)
                  }
                  options={statusOptions}
                />
                {formik.errors.status && formik.touched.status && (
                  <div className="text-danger">{formik.errors.status}</div>
                )}
              </div>
            </div>
            <div className="row mt-20">
              {/* Title */}
              <div className="col">
                <label htmlFor="title">Titre</label>
                <input
                  type="text"
                  id="title"
                  placeholder="Titre"
                  name="title"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  readOnly
                />
                {formik.errors.title && formik.touched.title && (
                  <div className="text-danger">{formik.errors.title}</div>
                )}
              </div>
              {/* Opening Date */}
              <div className="col">
                <label htmlFor="openingDate">Date ouverture</label>
                <DatePicker
                  id="openingDate"
                  name="openingDate"
                  className="form-control w-100"
                  selected={formik.values.openingDate}
                  onChange={(date) => formik.setFieldValue("openingDate", date)}
                  dateFormat="dd/MM/yyyy"
                  readOnly
                />
                {formik.errors.openingDate && formik.touched.openingDate && (
                  <div className="text-danger">{formik.errors.openingDate}</div>
                )}
              </div>
            </div>
            <div className="row mt-20">
              {/* State */}
              <div className="col">
                <label htmlFor="state">Etat</label>
                <Select
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("state", selectedOption)
                  }
                  options={etatOptions}
                  placeholder="Sélectionner..."
                  isDisabled
                />
                {formik.errors.state && formik.touched.state && (
                  <div className="text-danger">{formik.errors.state}</div>
                )}
              </div>
              {/* Title */}
              <div className="col">
                <label htmlFor="title">Source alerte</label>
                <input
                  type="url"
                  id="source"
                  placeholder="Source alerte"
                  name="source"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.source}
                  readOnly
                />
                {formik.errors.source && formik.touched.source && (
                  <div className="text-danger">{formik.errors.source}</div>
                )}
              </div>
            </div>
            {/* Description */}
            <div className="mt-20">
              <label htmlFor="description">Descriptif</label>
              <ReactQuill
                id="description"
                name="description"
                value={formik.values.description}
                onChange={(value) => formik.setFieldValue("description", value)}
                readOnly
              />
              {formik.errors.description && formik.touched.description && (
                <div className="text-danger">{formik.errors.description}</div>
              )}
            </div>
            {/* Description */}
            <div className="mt-20">
              <label htmlFor="description">Recommandation CICG</label>
              <ReactQuill
                id="recommandation"
                name="recommandation"
                value={formik.values.recommandation}
                onChange={(value) =>
                  formik.setFieldValue("recommandation", value)
                }
                readOnly
              />
              {formik.errors.description && formik.touched.recommandation && (
                <div className="text-danger">
                  {formik.errors.recommandation}
                </div>
              )}
            </div>
            <div className="row mt-20">
              {/* Tracking Users */}
              <div className="col">
                <label htmlFor="trackingUsers">Utilisateurs pour suivi</label>
                <Select
                  placeholder="Sélectionner..."
                  id="trackingUsers"
                  name="trackingUsers"
                  value={formik.values.trackingUsers}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("trackingUsers", selectedOption)
                  }
                  options={usersOptions}
                  isMulti
                  isDisabled
                />
                {formik.errors.trackingUsers &&
                  formik.touched.trackingUsers && (
                    <div className="text-danger">
                      {formik.errors.trackingUsers}
                    </div>
                  )}
              </div>

              {/* Traitement User */}
              <div className="col">
                <label htmlFor="traitementUser">
                  {" "}
                  Utilisateurs pour traitement
                </label>
                <Select
                  placeholder="Sélectionner..."
                  id="traitementUser"
                  name="traitementUser"
                  value={formik.values.traitementUser}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("traitementUser", selectedOption)
                  }
                  options={usersOptions}
                  isMulti
                  isDisabled
                />
                {formik.errors.traitementUser &&
                  formik.touched.traitementUser && (
                    <div className="text-danger">
                      {formik.errors.traitementUser}
                    </div>
                  )}
              </div>
            </div>
            <div className="row mt-20">
              {/* Remarque */}
              <div className="col">
                <label htmlFor="remarque">Remarque</label>
                <ReactQuill
                  id="remarque"
                  name="remarque"
                  value={formik.values.remarque}
                  onChange={(value) => formik.setFieldValue("remarque", value)}
                />
                {formik.errors.remarque && formik.touched.remarque && (
                  <div className="text-danger">{formik.errors.remarque}</div>
                )}
              </div>
              {/* Solution */}
              <div className="col">
                <label htmlFor="solution">Solution</label>
                <ReactQuill
                  id="solution"
                  name="solution"
                  value={formik.values.solution}
                  onChange={(value) => formik.setFieldValue("solution", value)}
                />
                {formik.errors.solution && formik.touched.solution && (
                  <div className="text-danger">{formik.errors.solution}</div>
                )}
              </div>
            </div>
            <div className="row mt-20">
              {/* Resumé */}
              <div className="col">
                <label htmlFor="resumeSituation">Résumé de situation</label>
                <ReactQuill
                  id="resumeSituation"
                  name="resumeSituation"
                  value={formik.values.resumeSituation}
                  onChange={(value) =>
                    formik.setFieldValue("resumeSituation", value)
                  }
                />
                {formik.errors.resumeSituation &&
                  formik.touched.resumeSituation && (
                    <div className="text-danger">
                      {formik.errors.resumeSituation}
                    </div>
                  )}
              </div>
              {/* explication */}
              <div className="col">
                <label htmlFor="explicationMinistere">
                  Explication du ministère
                </label>
                <ReactQuill
                  id="explicationMinistere"
                  name="explicationMinistere"
                  value={formik.values.explicationMinistere}
                  onChange={(value) =>
                    formik.setFieldValue("explicationMinistere", value)
                  }
                />
                {formik.errors.explicationMinistere &&
                  formik.touched.explicationMinistere && (
                    <div className="text-danger">
                      {formik.errors.explicationMinistere}
                    </div>
                  )}
              </div>
            </div>
            <div className="row mt-20">
              {/* Mesures palliatives */}
              <div className="col">
                <label htmlFor="mesurepalliatives">Mesures palliatives</label>
                <ReactQuill
                  id="mesurepalliatives"
                  name="mesurepalliatives"
                  value={formik.values.mesurepalliatives}
                  onChange={(value) =>
                    formik.setFieldValue("mesurepalliatives", value)
                  }
                />
                {formik.errors.mesurepalliatives &&
                  formik.touched.mesurepalliatives && (
                    <div className="text-danger">
                      {formik.errors.mesurepalliatives}
                    </div>
                  )}
              </div>
              {/* Traitement média */}
              <div className="col">
                <label htmlFor="traitementMedia">Traitement média</label>
                <ReactQuill
                  id="traitementMedia"
                  name="traitementMedia"
                  value={formik.values.traitementMedia}
                  onChange={(value) =>
                    formik.setFieldValue("traitementMedia", value)
                  }
                />
                {formik.errors.traitementMedia &&
                  formik.touched.traitementMedia && (
                    <div className="text-danger">
                      {formik.errors.traitementMedia}
                    </div>
                  )}
              </div>
            </div>
            <div className="row mt-20">
              {/* Closing Date */}
              <div className="col">
                <label htmlFor="closingDate">Date fermeture</label>
                <DatePicker
                  id="closingDate"
                  name="closingDate"
                  className="form-control w-100"
                  selected={formik.values.closingDate}
                  onChange={(date) => formik.setFieldValue("closingDate", date)}
                  dateFormat="dd/MM/yyyy"
                />
                {formik.errors.closingDate && formik.touched.closingDate && (
                  <div className="text-danger">{formik.errors.closingDate}</div>
                )}
              </div>

              {/* Attachments */}
              <div className="col">
                <label htmlFor="attachments">Pièce jointe</label>
                <FileDropzone onDrop={handleDrop} droppedFiles={droppedFiles} />
                {/* Display the dropped files */}

                {formik.errors.attachments && formik.touched.attachments && (
                  <div className="text-danger">{formik.errors.attachments}</div>
                )}
              </div>
            </div>
            {permitInterventionComments && (
              <div>
                {" "}
                <div className="row mt-20">
                  <div className="col-8">
                    <label htmlFor="title">Commentaires</label>
                    <textarea
                      id="Commentaire"
                      placeholder="Commentaire"
                      name="Commentaire"
                      className="form-control"
                      onChange={handleComment}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-1">
                    <button
                      type="button"
                      className="btn-primary-al float-right mt-20"
                      onClick={handleSubmitComment}
                    >
                      Envoyer
                    </button>
                  </div>
                </div>
                <div>
                  <div className="row mt-20">
                    <div className="col-8">
                      <hr />
                      <label htmlFor="title">Commentaires</label> Récents
                    </div>
                    <Message messages={messages} />
                  </div>
                </div>
              </div>
            )}
            <a href="/interventions" className="cta mt-20">
              Annuler
            </a>
            <button type="submit" className="btn-primary-al float-right mt-20">
              Modifier
            </button>
            <br />
            <br />
          </div>
        )}
      </form>
    </div>
  );
};

export default InterventionFormUpdate;
