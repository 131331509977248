import Layout from "./layout";
import "../assets/css/alertes.css";
import { useEffect, useState } from "react";
import Warining from "../assets/icons/fluent_warning-32-filled.svg";
import ellipse from "../assets/icons/Ellipse1.svg";
import Datatables from "../components/datatable";
import Services from "../services/global.service";
import edit from "../assets/icons/cil_pen.svg";
import DeleteModal from "../components/deleteModal";
import eye from "../assets/icons/solar_eye-outline.svg";
import trash from "../assets/icons/iconoir_trash.svg";
import { formatDistance, sub } from "date-fns";
import { fr } from "date-fns/locale";
import Loading from "../components/loading";
import Constant from "../utils/constant";

function Alertes() {
  const [alerteDatas, setAlerteDatas] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [permitCreateAlerte, setPermitCreateAlerte] = useState(false);
  const [permitValidateAlerte, setPermitValidateAlerte] = useState(false);
  const [permitVisualizeAlerte, setPermitVisualizeAlerte] = useState(false);
  const [permitUpdateAlerte, setPermitUpdateAlerte] = useState(false);
  const [permitDeleteAlerte, setPermitDeleteAlerte] = useState(false);

  const profil = localStorage.getItem("__profil");

  function currentDateTime() {
    var currentDate = new Date();
    let year = currentDate.getUTCFullYear();
    let month = ("0" + (currentDate.getUTCMonth() + 1)).slice(-2); // Adding 1 because getUTCMonth returns 0-indexed month
    let day = ("0" + currentDate.getUTCDate()).slice(-2);
    let hours = ("0" + currentDate.getUTCHours()).slice(-2);
    let minutes = ("0" + currentDate.getUTCMinutes()).slice(-2);
    let seconds = ("0" + currentDate.getUTCSeconds()).slice(-2);

    // Format the date string
    let gmtDateString =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;
    return gmtDateString;
  }

  const userAlertesAuthorization = () => {
    let permit = Services.getAuthorization("Create helpdesk");
    setPermitCreateAlerte(permit);
    let permitVisualise = Services.getAuthorization("Visualize Helpdesk");
    setPermitVisualizeAlerte(permitVisualise);
    let permitValidate = Services.getAuthorization("Validate Helpdesk");
    setPermitValidateAlerte(permitValidate);
    let permitUpdate = Services.getAuthorization("Update helpdesk");
    setPermitUpdateAlerte(permitUpdate);
    let permitDelete = Services.getAuthorization("Delete helpdesk");
    setPermitDeleteAlerte(permitDelete);
  };

  const TimeAgo = ({ updatedDate }) => {
    // Utilisez formatDistanceToNow pour obtenir la différence formatée
    let now = currentDateTime();
    const timeLeft = formatDistance(new Date(updatedDate), new Date(now), {
      addSuffix: true,
      locale: fr,
    });

    return (
      <span className="modified-txt">
        <img src={ellipse} alt="ellipse" className="mx-2" />
        {timeLeft}
      </span>
    );
  };

  const columns = [
    {
      selector: (row) => row.Titre,
      name: "Nom de l'alerte",
      sortable: false,
      width: "40%",
      //sortIcon: <img src={filterIcon} alt="Sort" />,
    },

    {
      name: "Priorité",
      selector: (row) => row.Priorité,
      cell: (row) => {
        return (
          <div className={`priority-${row.Priorité}`}>
            {row.Priorité === "Faible"
              ? "Faible"
              : row.Priorité === "Elevée"
              ? "Elevée"
              : "Moyenne"}
          </div>
        );
      },
      //format: (row) => new Date(row.date_last_cnx).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Publiée le",
      selector: (row) => row["Date de création"],
      format: (row) => new Date(row["Date de création"]).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Modifiée",
      selector: (row) => row.modifiedAt,
      format: (row) => new Date(row.modifiedAt).toLocaleDateString(),
      sortable: true,
      cell: (row) => {
        return (
          <p>
            <TimeAgo
              // createdDate={row["Date de création"]}
              updatedDate={row["Date de modification"]}
            />
          </p>
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          {/* <a className="cta" onClick={() => console.log(row)}><img src={eye} alt="icon eye" /></a> */}
          {permitUpdateAlerte && (
            <a className="cta" href={`/alertes/update/${row.id}`}>
              <img src={edit} alt="icon edit" className="w-75" />
            </a>
          )}
          {permitDeleteAlerte && (
            <a className="cta" onClick={() => handleButton1(row)}>
              <img src={trash} alt="icon delete" className="w-75" />
            </a>
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleButton1 = (row) => {
    // Logic for button 1
    console.log("Button 1 clicked for user:", row);
    setIsOpenDelete(!isOpenDelete);
  };

  const getListAlertes = () => {
    setIsLoading(true);
    const data = {
      setup: "gouvalert2",
      moduleName: "HelpDesk",
      role: localStorage.getItem("role"),
      group: localStorage.getItem("group"),
      profil: localStorage.getItem("profil"),
      userid: localStorage.getItem("userid"),
    };
    Services.getAllByData("module/list", data)
      .then((res) => {
        console.log(res);
        var data = res.data.data;
        data.sort((a, b) =>
          new Date(a["Date de modification"]) <
          new Date(b["Date de modification"])
            ? 1
            : -1
        );
        setAlerteDatas(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  function DeleteAlerte() {}

  const hideModal = () => {
    setIsOpenDelete(!isOpenDelete);
  };
  useEffect(() => {
    getListAlertes();
    userAlertesAuthorization();
  }, []);

  {
    /**vue UI */
  }
  return (
    <Layout>
      {isOpenDelete ? (
        <DeleteModal
          isOpenDelete={isOpenDelete}
          setIsOpenDelete={setIsOpenDelete}
          hideModal={hideModal}
          DeleteEvent={DeleteAlerte}
          title="Souhaitez-vous supprimer l'alerte ?"
        />
      ) : (
        ""
      )}
      <div className="min-vh-100 bg-color container-al">
        <div className="">
          <div className="row ">
            <div className="col-lg-6">
              <h1>
                Liste des alertes{" "}
                {/* <img
                  src={Warining}
                  alt="warning icon"
                  className="mx-2 icon-title"
                ></img> */}
              </h1>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-end">
              {permitCreateAlerte && (
                <a className="btn-secondary-al mx-2" href="/alertes/create">
                  Créer alerte
                </a>
              )}
              {permitVisualizeAlerte && (
                <a className="btn-secondary-al mx-2" href="/alertes/rapport">
                  Visualiser le rapport
                </a>
              )}
              {/* {isShowTransRap ? (
                <button className="btn-primary-al mx-2">
                  Transmettre le rapport
                </button>
              ) : (
                ""
              )} */}
            </div>
          </div>
          {/* <input className="form-control mt-42" type="search" id="search" placeholder="Recherche"/> */}

          <div className=" mt-42 bg-primary-al">
            {isLoading ? (
              <Loading />
            ) : (
              <Datatables
                data={alerteDatas}
                tabs={[
                  { value: "En cours", name: "En cours" },
                  { value: "A transmettre", name: "A transmettre" },
                  { value: "Ne pas transmettre", name: "Ne pas transmettre" },
                  { value: "Transmis", name: "Transmis" },
                  { value: "all", name: "Toutes les alertes" },
                ]}
                columns={columns}
                path={`alertes/details`}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default Alertes;
