import Layout from "./layout";
import "../assets/css/rapport.css";
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Warining from "../assets/icons/fluent_warning-32-filled.svg";
import Datatables from "../components/datatable";
import { useParams } from "react-router";
import chevron from "../assets/icons/ep_arrow-up.svg";
import iconBack from "../assets/icons/mingcute_arrow-up-line.svg";
import mailIcon from "../assets/icons/ion_mail-outline.svg";
import downloadIcon from "../assets/icons/lucide_download.svg";
import mediumIcon from "../assets/icons/fluent_info-24-regular.svg";
import urlData from "../assets/uploads/upload1.png";
import DatatablesRapport from "../components/datatableRapport";
import Loading from "../components/loading";
import Services from "../services/global.service";
import Constant from "../utils/constant";
import jsPDF from "jspdf";

function Rapport() {
  const [alerts, setAlerts] = useState([]);
  const [alertHigh_rap, setAlertHigh] = useState([]);
  const [alertMoys_rap, setAlertMoys] = useState([]);
  const [alertfaible_rap, setAlertfaible] = useState([]);
  const [alertHighs, setAlertHighs] = useState([]);
  const [alertHighMedium, setAlertHighMedium] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowTag, setIsShowTag] = useState(true);
  const [showHighSection, setShowHighSection] = useState(false);
  const [showHighMediumSection, setShowHighMediumSection] = useState(false);
  const [showHighMediumWeakSection, setShowHighMediumWeakSection] =
    useState(true);
  const [permitValidateAlerte, setPermitValidateAlerte] = useState(false);
  const rapportRef = useRef(null);
  const rapporHightRef = useRef(null);
  const rapportHighMediumRef = useRef(null);
  const navigate = useNavigate();

  const columns = [
    {
      selector: (row) => row.name,
      //  name: "Nom de l'alerte",
      sortable: false,
      width: "40%",
      //sortIcon: <img src={filterIcon} alt="Sort" />,
    },

    {
      //  name: 'Publiée le',
      selector: (row) => row.datePublication,
      format: (row) => new Date(row.datePublication).toLocaleDateString(),
      sortable: true,
    },
    {
      //  name: 'Priorité',
      selector: (row) => row.Priorité,
      cell: (row) => {
        return <div className={`priority-${row.Priorité}`}>{row.Priorité}</div>;
      },
      //format: (row) => new Date(row.date_last_cnx).toLocaleDateString(),
      sortable: true,
    },
  ];

  const downloadRapport = () => {
    setIsShowTag(false);
    setTimeout(() => {
      var content = rapportRef.current;
      setIsLoading(true);
      let pdf = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: "a4",
      });

      console.log(content);
      console.log(rapportRef.current.children);
      const width = pdf.internal.pageSize.getWidth();
      pdf
        .html(content, {
          width: width,
          windowWidth: 794,
          margin: [80, 80, 80, 80],
          html2canvas: { scale: 0.57, useCORS: true },
        })
        .then(() => {
          pdf.save("Rapport.pdf");
          setIsShowTag(true);
          setIsLoading(false);
        });
    }, 0);
  };

  const sendRapportHigh = async () => {
    if (alertHighs.length === 0) {
      console.log("rapport high ok");
      setShowHighMediumSection(false);
      setShowHighSection(false);
      setIsLoading(false);
      navigate("/alertes");
      return;
    }
    setIsShowTag(false);
    var content = rapporHightRef.current;
    setIsLoading(true);
    let pdf = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
    });
    const width = pdf.internal.pageSize.getWidth();
    pdf
      .html(content, {
        width: width,
        windowWidth: 794,
        margin: [80, 80, 80, 80],
        html2canvas: { scale: 0.57, useCORS: true },
      })
      .then(() => {
        let output = pdf.output("blob", { filename: "RapportHigh.pdf" });
        console.log("output: " + output);
        let uploadData = new FormData();
        uploadData.append("documents[]", output);
        uploadData.append("type", "rapport");
        console.log("getformdata", uploadData.get("documents[]"));
        Services.create(uploadData, "upload/documents", true).then(
          (filePath) => {
            const data = {
              setup: "gouvalert2",
              alertesHightPriority: alertHigh_rap,
              alertesMediumPriority: alertMoys_rap,
              alertesWeakPriority: alertfaible_rap,
              documentpath: filePath.data[0],
              userid: localStorage.getItem("userid"),
            };
            Services.getAllByData("rapport/gouvhigh/email", data).then(
              (response) => {
                setIsShowTag(true);
                setIsLoading(false);
                setShowHighSection(false);
              }
            );
          }
        );
      });
  };

  const sendRapportHighMedium = async () => {
    if (alertHighMedium.length === 0) {
      console.log("rapport Medium high ok");
      setShowHighMediumSection(false);
      setShowHighSection(false);
      setIsLoading(false);
      navigate("/alertes");
    }
    setIsShowTag(false);
    var content = rapportHighMediumRef.current;
    setIsLoading(true);
    let pdf = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
    });
    const width = pdf.internal.pageSize.getWidth();
    pdf
      .html(content, {
        width: width,
        windowWidth: 794,
        margin: [80, 80, 80, 80],
        html2canvas: { scale: 0.57, useCORS: true },
      })
      .then(() => {
        let output = pdf.output("blob", {
          filename: "RapportHighMedium.pdf",
        });
        console.log("output: " + output);
        let uploadData = new FormData();
        uploadData.append("documents[]", output);
        uploadData.append("type", "rapport");
        console.log("getformdata", uploadData.get("documents[]"));
        Services.create(uploadData, "upload/documents", true).then(
          (filePath) => {
            const data = {
              setup: "gouvalert2",
              alertesHightPriority: alertHigh_rap,
              alertesMediumPriority: alertMoys_rap,
              alertesWeakPriority: alertfaible_rap,
              documentpath: filePath.data[0],
              userid: localStorage.getItem("userid"),
            };
            Services.getAllByData("rapport/gouvhighmedium/email", data).then(
              (response) => {
                setIsShowTag(true);
                setIsLoading(false);
                setShowHighMediumSection(false);
                setShowHighSection(true);
                setTimeout(() => {
                  sendRapportHigh().then((value) => {
                    console.log("rapport high ok");
                    setShowHighMediumSection(false);
                    setShowHighSection(false);
                    setIsLoading(false);
                    navigate("/alertes");
                  });
                }, 0);
              }
            );
          }
        );
      });
  };

  const sendRapport = async () => {
    setIsShowTag(false);
    setTimeout(() => {
      var content = rapportRef.current;
      setIsLoading(true);
      let pdf = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: "a4",
      });
      const width = pdf.internal.pageSize.getWidth();
      pdf
        .html(content, {
          width: width,
          windowWidth: 794,
          margin: [80, 80, 80, 80],
          html2canvas: { scale: 0.57, useCORS: true },
        })
        .then(() => {
          let output = pdf.output("blob", { filename: "Rapport.pdf" });
          console.log("output: " + output);
          let uploadData = new FormData();
          uploadData.append("documents[]", output);
          uploadData.append("type", "rapport");
          console.log("getformdata", uploadData.get("documents[]"));
          Services.create(uploadData, "upload/documents", true).then(
            (filePath) => {
              const data = {
                setup: "gouvalert2",
                alertesHightPriority: alertHigh_rap,
                alertesMediumPriority: alertMoys_rap,
                alertesWeakPriority: alertfaible_rap,
                documentpath: filePath.data[0],
                userid: localStorage.getItem("userid"),
              };
              Services.getAllByData(
                "rapport/pointfocal_dircab/send",
                data
              ).then((response) => {
                setIsShowTag(true);
                setIsLoading(false);
                setShowHighMediumWeakSection(false);
                setShowHighMediumSection(true);
                setTimeout(() => {
                  sendRapportHighMedium().then((response) => {
                    console.log("rapport high & medium ok");
                  });
                }, 0);
              });
            }
          );
        });
    }, 0);
  };

  const userAlertesAuthorization = () => {
    let permitValidate = Services.getAuthorization("Validate Helpdesk");
    setPermitValidateAlerte(permitValidate);
  };

  const convertImageToBase64 = (imageURI) => {
    console.log("fileUri " + imageURI);
    var src = "data:image/png;base64, " + imageURI;
    return src;
  };

  const getListAlertes = () => {
    setIsLoading(true);
    let uri = "rapport/respo_cicg/view";
    const data = {
      setup: "gouvalert2",
      moduleName: "HelpDesk",
      role: localStorage.getItem("role"),
      group: localStorage.getItem("group"),
      profil: localStorage.getItem("profil"),
      userid: localStorage.getItem("userid"),
    };
    Services.getAllByData(uri, data)
      .then((res) => {
        var data = res.data.data;
        let alertHigh = data?.alertesHightPriority;
        setAlertHigh(alertHigh);
        alertHigh.sort((a, b) =>
          new Date(a["Date de modification"]) <
          new Date(b["Date de modification"])
            ? 1
            : -1
        );
        let alertMoys = data?.alertesMediumPriority;
        setAlertMoys(alertMoys);
        alertMoys.sort((a, b) =>
          new Date(a["Date de modification"]) <
          new Date(b["Date de modification"])
            ? 1
            : -1
        );
        let alertfaible = data?.alertesWeakPriority;
        setAlertfaible(alertfaible);
        alertfaible.sort((a, b) =>
          new Date(a["Date de modification"]) <
          new Date(b["Date de modification"])
            ? 1
            : -1
        );
        let ah = [];
        let am = [];
        let aw = [];
        let urls = [];
        alertHigh.map((item) => {
          let alert = {
            _id: item?.id,
            name: item?.Titre,
            priority: item["Priorité"],
            datePublication: new Date(item["Date ouverture"]),
            modifiedAt: new Date(item["Date de modification"]),
            Priorité: item["Priorité"],
            descriptif: item["Descriptif"],
            link: item["Source alerte"],
            files: item?.files == undefined ? [] : item?.files,
            suivi: item?.Suivi == undefined ? [] : item?.Suivi,
            traitement: item?.Traitement == undefined ? [] : item?.Traitement,
          };
          let files = [];
          alert.files.map((fileuri) => {
            files.push(convertImageToBase64(fileuri));
          });
          alert.files = files;
          ah.push(alert);
        });
        alertMoys.map((item) => {
          let alert = {
            _id: item?.id,
            name: item?.Titre,
            priority: item["Priorité"],
            datePublication: new Date(item["Date ouverture"]),
            modifiedAt: new Date(item["Date de modification"]),
            Priorité: item["Priorité"],
            descriptif: item["Descriptif"],
            link: item["Source alerte"],
            files: item?.files == undefined ? [] : item?.files,
            suivi: item?.Suivi == undefined ? [] : item?.Suivi,
            traitement: item?.Traitement == undefined ? [] : item?.Traitement,
          };
          let files = [];
          console.log(alert.files);
          alert.files.map((fileuri) => {
            files.push(convertImageToBase64(fileuri));
          });
          alert.files = files;
          am.push(alert);
        });
        alertfaible.map((item) => {
          let alert = {
            _id: item?.id,
            name: item?.Titre,
            priority: item["Priorité"],
            datePublication: new Date(item["Date ouverture"]),
            modifiedAt: new Date(item["Date de modification"]),
            Priorité: item["Priorité"],
            descriptif: item["Descriptif"],
            link: item["Source alerte"],
            files: item?.files == undefined ? [] : item?.files,
            suivi: item?.Suivi == undefined ? [] : item?.Suivi,
            traitement: item?.Traitement == undefined ? [] : item?.Traitement,
          };
          let files = [];
          console.log(alert.files);
          alert.files.map((fileuri) => {
            files.push(convertImageToBase64(fileuri));
          });
          alert.files = files;
          aw.push(alert);
        });
        let alerts = [...ah, ...am, ...aw];
        setAlertHighs(ah);
        let alertHighMediums = [...ah, ...am];
        setAlertHighMedium(alertHighMediums);
        localStorage.removeItem("urls");
        localStorage.setItem("urls", urls);
        console.log("urls: " + urls);
        setAlerts(alerts);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getListAlertes();
    userAlertesAuthorization();
  }, []);

  {
    /******* User Interface */
  }
  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="min-vh-100 bg-color rapport container-al" id="rapport">
          <div className=" ">
            <div className="row">
              <div className="bread-crumb">
                <span className="bread-item">Liste des Alertes </span>
                <img src={chevron} alt="chevron"></img>
                <span className="bread-item">Visualiser le rapport</span>
              </div>
              <div className="retour pt-26 mb-20">
                <img src={iconBack} alt="iconBack"></img>
                <a href="/alertes" className="retour-link">
                  Retour
                </a>
              </div>
              <div className="row mt-42">
                <div className="col-md-7">
                  <h1></h1>
                </div>
                <div className="col-md-5 text-right">
                  <button
                    onClick={downloadRapport}
                    className="btn-secondary-al mx-3"
                  >
                    <img src={downloadIcon} alt="mail cion" />
                  </button>
                  {permitValidateAlerte && (
                    <button className="btn-primary-al" onClick={sendRapport}>
                      <img src={mailIcon} alt="mail cion" /> Envoyer le rapport
                      par mail
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/******* UI High & Medium & Weak helpdesk */}
            {showHighMediumWeakSection && (
              <div className=" " ref={rapportRef}>
                <div className="row mt-42">
                  <div className="col-md-12">
                    <h1>
                      Relevé des alertes majeures postées dans la plateforme
                      interne CICG{" "}
                    </h1>
                  </div>
                </div>

                <div className="bref">
                  <span className="date">
                    Du {new Date().toLocaleDateString("fr-FR")}
                  </span>
                </div>

                {/* <input className="form-control mt-42" type="search" id="search" placeholder="Recherche"/> */}
                <div className="container mt-42 bg-primary-al">
                  {isShowTag && (
                    <div className="list-alertes">
                      <p className="subTitle">Liste des alertes</p>
                      <DatatablesRapport data={alerts} columns={columns} />
                    </div>
                  )}
                  {alerts.map((alert, key) => (
                    <div className="row mt-48" key={key}>
                      <div className="col-md-7">
                        <h4>{alert.name} </h4>
                        <div className="mt-12">
                          <span>
                            Publié le{" "}
                            {alert.datePublication.toLocaleDateString("fr-FR")}
                          </span>
                          <span className={`priority-${alert.priority} mx-2`}>
                            {alert.priority}
                          </span>
                        </div>
                        <p
                          className="content-alerte mt-24"
                          dangerouslySetInnerHTML={{
                            __html: alert.descriptif,
                          }}
                        ></p>
                      </div>
                      <div className="col-md-5">
                        <div className="list-alertes">
                          <span>Pour suivi</span>
                          {alert.suivi.map((s, key) => (
                            <p key={key}>{s}</p>
                          ))}
                          <hr />
                          <span>Pour traitement</span>
                          {alert.traitement.map((t, key) => (
                            <p key={key}>{t}</p>
                          ))}
                        </div>
                        <div className="police-alerte mt-24">
                          {alert.files.map((i, key) => (
                            <img key={key} src={i} alt="img-" />
                          ))}
                          <p className="link">{alert.link}</p>
                        </div>
                      </div>
                      <hr style={{ marginTop: "15px" }} />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/******* UI High & Medium & Weak helpdesk */}
            {/*********UI high helpdesk */}
            {showHighSection && (
              <div className=" " ref={rapporHightRef}>
                <div className="row mt-42">
                  <div className="col-md-12">
                    <h1>
                      Relevé des alertes majeures postées dans la plateforme
                      interne CICG{" "}
                    </h1>
                  </div>
                </div>

                <div className="bref">
                  <span className="date">
                    Du {new Date().toLocaleDateString("fr-FR")}
                  </span>
                </div>

                {/* <input className="form-control mt-42" type="search" id="search" placeholder="Recherche"/> */}
                <div className="container mt-42 bg-primary-al">
                  {isShowTag && (
                    <div className="list-alertes">
                      <p className="subTitle">Liste des alertes</p>
                      <DatatablesRapport data={alerts} columns={columns} />
                    </div>
                  )}
                  {alertHighs.map((alert, key) => (
                    <div className="row mt-48" key={key}>
                      <div className="col-md-7">
                        <h4>{alert.name} </h4>
                        <div className="mt-12">
                          <span>
                            Publié le{" "}
                            {alert.datePublication.toLocaleDateString("fr-FR")}
                          </span>
                          <span className={`priority-${alert.priority} mx-2`}>
                            {alert.priority}
                          </span>
                        </div>
                        <p
                          className="content-alerte mt-24"
                          dangerouslySetInnerHTML={{
                            __html: alert.descriptif,
                          }}
                        ></p>
                      </div>
                      <div className="col-md-5">
                        <div className="list-alertes">
                          <span>Pour suivi</span>
                          {alert.suivi.map((s, key) => (
                            <p key={key}>{s}</p>
                          ))}
                          <hr />
                          <span>Pour traitement</span>
                          {alert.traitement.map((t, key) => (
                            <p key={key}>{t}</p>
                          ))}
                        </div>
                        <div className="police-alerte mt-24">
                          {alert.files.map((i, key) => (
                            <img key={key} src={i} alt="img-" />
                          ))}
                          <p className="link">{alert.link}</p>
                        </div>
                      </div>
                      <hr style={{ marginTop: "15px" }} />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/*********UI high helpdesk */}
            {/*********UI high & Medium helpdesk */}
            {showHighMediumSection && (
              <div className=" " ref={rapportHighMediumRef}>
                <div className="row mt-42">
                  <div className="col-md-12">
                    <h1>
                      Relevé des alertes majeures postées dans la plateforme
                      interne CICG{" "}
                    </h1>
                  </div>
                </div>

                <div className="bref">
                  <span className="date">
                    Du {new Date().toLocaleDateString("fr-FR")}
                  </span>
                </div>

                {/* <input className="form-control mt-42" type="search" id="search" placeholder="Recherche"/> */}
                <div className="container mt-42 bg-primary-al">
                  {isShowTag && (
                    <div className="list-alertes">
                      <p className="subTitle">Liste des alertes</p>
                      <DatatablesRapport data={alerts} columns={columns} />
                    </div>
                  )}
                  {alertHighMedium.map((alert, key) => (
                    <div className="row mt-48" key={key}>
                      <div className="col-md-7">
                        <h4>{alert.name} </h4>
                        <div className="mt-12">
                          <span>
                            Publié le{" "}
                            {alert.datePublication.toLocaleDateString("fr-FR")}
                          </span>
                          <span className={`priority-${alert.priority} mx-2`}>
                            {alert.priority}
                          </span>
                        </div>
                        <p
                          className="content-alerte mt-24"
                          dangerouslySetInnerHTML={{
                            __html: alert.descriptif,
                          }}
                        ></p>
                      </div>
                      <div className="col-md-5">
                        <div className="list-alertes">
                          <span>Pour suivi</span>
                          {alert.suivi.map((s, key) => (
                            <p key={key}>{s}</p>
                          ))}
                          <hr />
                          <span>Pour traitement</span>
                          {alert.traitement.map((t, key) => (
                            <p key={key}>{t}</p>
                          ))}
                        </div>
                        <div className="police-alerte mt-24">
                          {alert.files.map((i, key) => (
                            <img key={key} src={i} alt="img-" />
                          ))}
                          <p className="link">{alert.link}</p>
                        </div>
                      </div>
                      <hr style={{ marginTop: "15px" }} />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/*********UI high & Medium helpdesk */}
          </div>
        </div>
      )}
    </Layout>
  );
}
export default Rapport;
