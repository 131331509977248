import { Route, Routes, BrowserRouter, redirect  } from "react-router-dom";
import './App.css';
import './assets/css/btn.css';
import './assets/css/ui.css';
import Alertes from "./views/alertes";
import Dashboard from "./views/dashboard";
import Rapport from "./views/rapport";
import Intervention from "./views/interventions";
import Rapports from "./views/rapports";
import CreateAlerte from "./views/createAlerte";
import Users from "./views/users";
import CreateUser from "./views/createUser";
import Login from "./views/login";
import DetailsAlerte from "./views/detailsAlerte";
import UpdateAlerte from "./views/updateAlerte";
import UpdateUser from "./views/updateUser";
import UpdateIntervention from "./views/updateIntervention";
import DetailsIntervention from "./views/detailsIntervention";
import RapportIntervention from "./views/rapportIntervention";
import ProtectedRoute from './utils/protectedRoutes'



function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/alertes" element={<Alertes />} />
                  <Route path="/alertes/rapport" element={<Rapport />} />
                  <Route path="/alertes/rapport/:id" element={<Rapport />} />
                  <Route path="/alertes/details/:id" element={<DetailsAlerte />} />
                  <Route path="/interventions/details/:id" element={<DetailsIntervention />} />
                  <Route path="/interventions" element={<Intervention />} />
                  <Route path="/interventions/rapport" element={<RapportIntervention />} />
                  <Route path="/rapports" element={<Rapports />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/users/create" element={<CreateUser />} />
                  <Route path="/alertes/create" element={<CreateAlerte />} />
                  <Route path="/alertes/update/:id" element={<UpdateAlerte />} />
                  <Route path="/interventions/update/:id" element={<UpdateIntervention/>} />
                  <Route path="/users/update/:id" element={<UpdateUser />} />
                  </Routes>
                </ProtectedRoute>
              }
            />
          </Routes>
      </BrowserRouter>
      </div>
  );
}

export default App;
