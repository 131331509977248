import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FileDropzone from "./fileDropZone";
import "../assets/css/alertes.css";
import Services from "../services/global.service";
import Loading from "../components/loading";
import Constant from "../utils/constant";

const AlertForm = () => {
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [etatOptions, setEtatOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);
  const [saveResult, setSaveResult] = useState(0);
  const navigate = useNavigate();
  const [file, setFile] = useState("");

  const profil = localStorage.getItem("__profil");

  const handleDrop = (acceptedFiles) => {
    // Handle the dropped files here
    console.log("Accepted Files:", acceptedFiles);
    setDroppedFiles(acceptedFiles);
  };

  const initialValues = {
    priority: "",
    status: "",
    title: "",
    openingDate: null,
    state: "",
    description: "",
    trackingUsers: [],
    taritUser: [],
    attachments: [],
  };

  const validationSchema = Yup.object().shape({
    // Define your validation schema here
    // priority: Yup.string().required('Champs requis'),
    //   status: Yup.string().required('Champs requis'),
    title: Yup.string().required("Champs requis"),
    openingDate: Yup.date().required("Champs requis"),
    source: Yup.string()
      .matches(
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
        "L'url n'est pas correct"
      )
      .url("Veuillez saisir une URL")
      .required("Champs requis"),
    //    state: Yup.string().required('Champs requis'),
    description: Yup.string().required("Champs requis"),
    // communicationUser: Yup.string().required('Communication User is required'),
    // actionUser: Yup.string().required('Action User is required'),
  });

  const getDataInputs = () => {
    setIsLoading(true);
    Services.getDataSelectedInput()
      .then(
        axios.spread(
          (
            { data: priority },
            { data: status },
            { data: etats },
            { data: groups } //{data:categories},
          ) => {
            ////get priority
            let priorities = [];
            priority?.data?.forEach((element) => {
              var p = { value: element, label: element };
              priorities.push(p);
            });
            setPriorityOptions(priorities);
            // ////get status
            let sts = [];
            status?.data?.forEach((st) => {
              var s = { value: st, label: st };
              sts.push(s);
            });
            setStatusOptions(sts);
            // ////get etats
            let ets = [];
            etats?.data?.forEach((et) => {
              if (et != "Transmis") {
                var e = { value: et, label: et };
                ets.push(e);
              }
            });
            setEtatOptions(ets);
            // ////get users groups
            let groupes = [];
            groups?.data?.forEach((gp) => {
              var g = { value: gp["id"], label: gp["value"] };
              groupes.push(g);
            });
            setUsersOptions(groupes);
            setIsLoading(false);
          }
        )
      )
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getDataInputs();
  }, []);

  const onSubmit = async (values, { resetForm }) => {
    console.log(values.trackingUsers);
    try {
      setIsLoading(true);
      var files = [];
      if (droppedFiles.length > 0) {
        let uploadData = new FormData();
        droppedFiles.forEach((file) => {
          uploadData.append("documents[]", file);
        });
        console.log("getformdata", uploadData.get("documents[]"));
        files = await Services.create(uploadData, "upload/documents", true);
      }
      const object = {
        Titre: values.title,
        Priorité: values.priority.value,
        Statut: values.status.value,
        "Date ouverture": values.openingDate,
        Etat: values.state.value,
        "Source alerte": values.source,
        Descriptif: values.description,
        "Recommandation CICG": values.recommandation,
        "Utilisateurs pour suivi": values.trackingUsers,
        "Utilisateurs pour traitement": values.taritUser,
        documents: droppedFiles.length > 0 ? files?.data : files,
      };
      let formData = {
        setup: Constant.SETUP,
        moduleName: Constant.HELPDESK,
        object: object,
        userid: localStorage.getItem("__id"),
      };
      let response = await Services.create(formData, "module/save");
      if (response?.data?.success) {
        setSaveResult(1);
        resetForm({ values: "" });
        setDroppedFiles([]);
      } else {
        setSaveResult(2);
      }
      setIsLoading(false);
      navigate("/alertes");
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const getUrlImage = async (e) => {
    let pageUrl = e.target.value;
    // let isUrl = pageUrl.matches(
    //   " /((https?)://)?(www.)?[a-z0-9]+(.[a-z]{2,}){1,3}(#?/?[a-zA-Z0-9#]+)*/?(?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,"
    // );
    //if (isUrl) {
    // const browser = await puppeteer.launch();
    // const page = await browser.newPage();
    // await page.goto(pageUrl);
    // const screenshot = await page.screenshot({ encoding: "base64" });
    // await browser.close();
    // setFile(screenshot);
    //}
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  {
    /******Vue UI ***********/
  }
  return (
    <div>
      {saveResult === 1 ? (
        <div class="alert alert-success" role="alert">
          Votre alerte a été enregistrée avec succès
        </div>
      ) : saveResult == 2 ? (
        <div class="alert alert-danger" role="alert">
          Une erreur s'est produite.Veuillez réessayer ultérieurement
        </div>
      ) : null}
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            <div className="row mt-42">
              {/* Priority */}

              <div className="col">
                <label htmlFor="priority">Priorité</label>
                <Select
                  id="priority"
                  name="priority"
                  value={formik.values.priority}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("priority", selectedOption)
                  }
                  options={priorityOptions}
                  placeholder="Sélectionner..."
                />
                {formik.errors.priority && formik.touched.priority && (
                  <div className="text-danger">{formik.errors.priority}</div>
                )}
              </div>
              {/* Status */}
              <div className="col">
                <label htmlFor="status">Statut</label>
                <Select
                  id="status"
                  placeholder="Sélectionner..."
                  name="status"
                  value={formik.values.status}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("status", selectedOption)
                  }
                  options={statusOptions}
                />
                {formik.errors.status && formik.touched.status && (
                  <div className="text-danger">{formik.errors.status}</div>
                )}
              </div>
            </div>
            <div className="row mt-20">
              {/* Title */}
              <div className="col">
                <label htmlFor="title">Titre</label>
                <input
                  type="text"
                  id="title"
                  placeholder="Titre"
                  name="title"
                  className="form-control"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
                {formik.errors.title && formik.touched.title && (
                  <div className="text-danger">{formik.errors.title}</div>
                )}
              </div>
              {/* Opening Date */}
              <div className="col">
                <label htmlFor="openingDate">Date ouverture</label>
                <DatePicker
                  id="openingDate"
                  name="openingDate"
                  className="form-control w-100"
                  selected={formik.values.openingDate}
                  onChange={(date) => formik.setFieldValue("openingDate", date)}
                  dateFormat="dd/MM/yyyy"
                />
                {formik.errors.openingDate && formik.touched.openingDate && (
                  <div className="text-danger">{formik.errors.openingDate}</div>
                )}
              </div>
            </div>
            <div className="row mt-20">
              {/* State */}
              <div className="col">
                <label htmlFor="state">Etat</label>
                {/* <input
               type="text"
               id="state"
               name="state"
               className='form-control'
               onChange={formik.handleChange}
               onBlur={formik.handleBlur}
               value={formik.values.state}
               /> */}
                <Select
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("state", selectedOption)
                  }
                  options={etatOptions}
                  placeholder="Sélectionner..."
                />
                {formik.errors.state && formik.touched.state && (
                  <div className="text-danger">{formik.errors.state}</div>
                )}
              </div>
              {/* Title */}
              <div className="col">
                <label htmlFor="title">Source alerte</label>
                <input
                  type="url"
                  id="source"
                  placeholder="https://info.ci"
                  name="source"
                  className="form-control"
                  onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  onBlur={(e) => {
                    getUrlImage(e);
                  }}
                  value={formik.values.source}
                />
                {formik.errors.source && formik.touched.source && (
                  <div className="text-danger">{formik.errors.source}</div>
                )}
              </div>
            </div>
            {/* Description */}
            <div className="mt-20">
              <label htmlFor="description">Descriptif</label>
              <ReactQuill
                id="description"
                name="description"
                value={formik.values.description}
                onChange={(value) => formik.setFieldValue("description", value)}
              />
              {formik.errors.description && formik.touched.description && (
                <div className="text-danger">{formik.errors.description}</div>
              )}
            </div>
            {/* Description */}
            <div className="mt-20">
              <label htmlFor="description">Recommandation CICG</label>
              <ReactQuill
                id="recommandation"
                name="recommandation"
                value={formik.values.recommandation}
                onChange={(value) =>
                  formik.setFieldValue("recommandation", value)
                }
              />
              {formik.errors.description && formik.touched.recommandation && (
                <div className="text-danger">
                  {formik.errors.recommandation}
                </div>
              )}
            </div>
            <div className="row mt-20">
              {/* Tracking Users */}
              <div className="col">
                <label htmlFor="trackingUsers">Utilisateurs pour suivi</label>
                <Select
                  placeholder="Sélectionner..."
                  id="trackingUsers"
                  name="trackingUsers"
                  value={formik.values.trackingUsers}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("trackingUsers", selectedOption)
                  }
                  options={usersOptions}
                  isMulti
                />
                {formik.errors.trackingUsers &&
                  formik.touched.trackingUsers && (
                    <div className="text-danger">
                      {formik.errors.trackingUsers}
                    </div>
                  )}
              </div>
              {/* Communication User */}
              <div className="col">
                <label htmlFor="taritUser"> Utilisateurs pour traitement</label>
                <Select
                  placeholder="Sélectionner..."
                  id="taritUser"
                  name="taritUser"
                  value={formik.values.taritUser}
                  onChange={(selectedOption) =>
                    formik.setFieldValue("taritUser", selectedOption)
                  }
                  options={usersOptions}
                  isMulti
                />
                {formik.errors.taritUser && formik.touched.taritUser && (
                  <div className="text-danger">{formik.errors.taritUser}</div>
                )}
              </div>
            </div>
            <div className="row mt-20">
              {/* Attachments */}
              <div className="col">
                <label htmlFor="attachments">Pièce jointe</label>
                <FileDropzone
                  accept="image/png"
                  onDrop={handleDrop}
                  droppedFiles={droppedFiles}
                />
                {/* Display the dropped files */}
                {formik.errors.attachments && formik.touched.attachments && (
                  <div className="text-danger">{formik.errors.attachments}</div>
                )}
              </div>
            </div>
            <a href="/alertes" className="cta mt-20">
              Annuler
            </a>
            <button type="submit" className="btn-primary-al float-right mt-20">
              Sauvegarder
            </button>
            <br />
            <br />
          </div>
        )}
      </form>
    </div>
  );
};

export default AlertForm;
